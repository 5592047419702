import { useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import getDataInObjectDepth from "utils/getDataInObject";

const TableAction = ({ param, newRoute, onClick, id, disable, active, pathId, ...props }) => {
  const currentId = useMemo(() => (param && pathId ? getDataInObjectDepth(pathId, param) : false), []);
  const componentRef = useRef();
  const paperRef = useRef();
  param = param || {};
  console.log({ param });
  async function printDiv() {
    var myframe = document.createElement("iframe");
    myframe.style.position = "absolute";
    myframe.style.top = "-10000px";
    document.body.appendChild(myframe);

    setTimeout(function () {
      myframe.focus();
      myframe.contentWindow.print();
      myframe.parentNode.removeChild(myframe);
    }, 1000);
    window.focus();
  }

  return (
    <div className="d-flex">
      {active?.manuel ? (
        active?.manuel?.route ? (
          <Link
            to={active?.manuel?.route ? newRoute.base + newRoute?.[active?.manuel?.route] + "/" + param?.id : "#"}
            className={`btn btn-secondary shadow btn-xs sharp mr-1 action-btn rounded p-2 height-fit-content  ${active?.manuel?.class}`}
            onClick={active?.manuel?.onClick ? () => active?.manuel?.onClick?.(param) : ""}
          >
            <i className={active?.manuel?.icon || "mdi mdi-pencil"}></i>
            {active?.manuel?.label ? <div className="mt-2">{active?.manuel?.label}</div> : ""}
          </Link>
        ) : (
          <div
            className={`btn btn-secondary shadow btn-xs sharp mr-1 action-btn rounded p-2 height-fit-content  ${active?.manuel?.class}`}
            onClick={active?.manuel?.onClick ? () => active?.manuel?.onClick?.(param) : ""}
          >
            <i className={active?.manuel?.icon}></i>
            {active?.manuel?.label ? <div className={active?.manuel?.icon && "mt-2"}>{active?.manuel?.label}</div> : ""}
          </div>
        )
      ) : (
        ""
      )}
      {!disable?.edit ? (
        <Link
          to={
            id
              ? newRoute.base + newRoute.edit + "/" + (currentId || param?.id) + "/" + id
              : newRoute.base + newRoute.edit + "/" + (currentId || param?.id)
          }
          className=" btn btn-primary shadow btn-xs sharp mr-1 action-btn rounded p-2 height-fit-content "
        >
          <i className="mdi mdi-pencil"></i>
        </Link>
      ) : (
        ""
      )}
      {!disable?.delete ? (
        <span
          className="btn btn-danger shadow btn-xs sharp pointer action-btn mdi rounded p-2 height-fit-content "
          onClick={() => onClick({ data: [param], status: { label: "delete" } })}
        >
          <i className="mdi mdi-delete"></i>
        </span>
      ) : (
        ""
      )}

      {active?.show ? (
        <Link
          to={
            id
              ? newRoute.base + newRoute.show + "/" + (currentId || param?.id) + "/" + id
              : newRoute.base + newRoute.show + "/" + (currentId || param?.id)
          }
          className=" btn btn-warning shadow btn-xs sharp mr-1 action-btn rounded p-2 height-fit-content "
        >
          <i className="mdi mdi-eye"></i>
        </Link>
      ) : (
        ""
      )}
      {active?.print ? (
        <>
          <span
            className="btn btn-success shadow btn-xs sharp pointer action-btn mdi rounded p-2 height-fit-content "
            onClick={printDiv}
            // onClick={() => onClick({ data: [param], status: { label: "print" } })}
          >
            <i className="mdi mdi-printer"></i>
          </span>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default TableAction;
