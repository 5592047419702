import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "title",
    Component: ({ param }) => param.title || "Unknown",
  },
  {
    header: "subtitle",
    Component: ({ param }) => param.subTitle || "Unknown",
  },
  {
    header: "description",
    Component: ({ param }) => {
      return (
        <span id="ellipsis-ex" class="d-inline-block text-truncate" style={{ maxWidth: "150px" }}>
          {param.description || "Unknown"}
        </span>
      );
    },
  },

  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
];
