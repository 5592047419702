import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  image: "image",
  subImage: "subImage",
  banner: "banner",
  description: "description",
  subDescription: "subDescription",
  descriptionLower: "descriptionLower",
  subDescriptionLower: "subDescriptionLower",

  translations: "translations",
  translate_language: "translate_language",
  translate_description: "translate_description",
  translate_descriptionLower: "translate_descriptionLower",
  translate_subDescription: "translate_subDescription",
  translate_subDescriptionLower: "translate_subDescriptionLower",
};

const translations = yup
  .object({
    [fieldNames.translate_description]: yup.string().required(ERRORS.pleaseEnterYour("description")).label("Description"),
    [fieldNames.translate_descriptionLower]: yup.string().required(ERRORS.pleaseEnterYour("descriptionLower")).label("Description Lower"),
    [fieldNames.translate_subDescription]: yup.string().required(ERRORS.pleaseEnterYour("subDescription")).label("Sub Description"),
    [fieldNames.translate_subDescriptionLower]: yup.string().required(ERRORS.pleaseEnterYour("subDescriptionLower")).label("Sub Description Lower"),
    [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
  })
  .defined();

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.description]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.description)).label(fieldNames.description),
    [fieldNames.descriptionLower]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descriptionLower)).label(fieldNames.descriptionLower),
    [fieldNames.subDescription]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subDescription)).label(fieldNames.subDescription),
    [fieldNames.subDescriptionLower]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subDescriptionLower)).label(fieldNames.subDescription),
    [fieldNames.image]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),
    [fieldNames.subImage]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.subImage)).label(fieldNames.subImage); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subImage)).label(fieldNames.subImage); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),
    [fieldNames.banner]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.banner)).label(fieldNames.banner); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.banner)).label(fieldNames.banner); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),
    [fieldNames.translations]: yup.array().of(translations),
  });
