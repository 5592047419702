import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  "name": "name",
  "image": "image",
  "isNewCategory": "isNewCategory",
  "categoryType": "categoryType",
  "isActive": "isActive",
  "parent": "parent",
  title: "title",
  subTitle: "subTitle",
  description: "description",
  translations: "translations",
  translate_title: "translate_title",
  translate_subTitle: "translate_subTitle",
  translate_description: "translate_description",
  translate_language: "translate_language",
  listOrderNumber: "listOrderNumber",
  translate_name: "translate_name",
  translate_language: "translate_language",
  language: "language",
};

const translations = yup
  .object({
    [fieldNames.translate_title]: yup.string().required(ERRORS.pleaseEnterYour("title")).label("Title"),
    [fieldNames.translate_subTitle]: yup.string().required(ERRORS.pleaseEnterYour("subTitle")).label("Sub Title"),
    [fieldNames.translate_description]: yup.string().required(ERRORS.pleaseEnterYour("description")).label("Description"),
    [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
  })
  .defined();

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.title]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.title)).label(fieldNames.title),
    [fieldNames.subTitle]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subTitle)).label(fieldNames.subTitle),
    [fieldNames.description]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.description)).label(fieldNames.description),
    [fieldNames.image]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),
    [fieldNames.translations]: yup.array().of(translations),
  });
