import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "email",
    Component: ({ param }) => param.email || "Unknown",
  },
  {
    header: "location",
    Component: ({ param }) => param.location || "Unknown",
  },
  {
    header: "phones",
    Component: ({ param }) => {
      return (
        <div id="phone-list">
          {param.phones.map((phone) => (
            <div key={phone} style={{ maxWidth: "150px", overflowWrap: "break-word" }}>
              {phone}
            </div>
          ))}
        </div>
      );
    },
  },
  {
    header: "telephones",
    Component: ({ param }) => {
      return (
        <div id="phone-list">
          {param.telephones.map((telephone) => (
            <div key={telephone} style={{ maxWidth: "150px", overflowWrap: "break-word" }}>
              {telephone}
            </div>
          ))}
        </div>
      );
    },
  },

  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
];
