import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import Loading from "components/Loading";
import Select from "react-select";
import swal from "sweetalert";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import styles from "./FolderDetail.module.scss";
import PaginationM from "components/PaginationM";
import PdfIcon from "assets/images/pdf_icon.png";
import { useTranslation } from "react-i18next";

const FolderDetail = ({ FOLDER_SLUG, handleClickedImage, selectedPhoto, handleModal, editable }) => {
  // TODO: separate logics to their own components.

  const { t } = useTranslation();
  const limit = 20;
  //  ----------------------------------------- VARIABLES/STATES/ROUTES ----------------------------------------- //

  // const QueryString = useQueryString({ sort: "desc", status: "1" });
  // const indexOfSelectedPhoto = data?.result?.findIndex((image) => image._id === selectedPhoto?._id);

  // const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [folderOptions, setFolderOptions] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderError, setFolderError] = useState(true);
  const [inputValue, setInputValue] = useState(selectedPhoto ? selectedPhoto.title : "");
  const [preLoad, setPreLoad] = useState(true);
  const [page, setPage] = useState(1);

  // const getFolders = async () => await axiosClient().get(api["folder"].list);
  const updatingFile = async (params) => await axiosClient().put(api["files"].base + "/edit/" + selectedPhoto._id, params);
  const changeStatus = async (params) => await axiosClient().delete(api["files"].base + "/" + params);
  const getting = async () => await axiosClient().get(api["files"].list, { params: { page, limit } });
  //  ----------------------------------------- GETTING QUERY ----------------------------------------- //

  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
  } = useQueryCustom({
    name: `galley_get`,
    url: getting,
    params: { page, limit },
  });
  //  ----------------------------------------- HANDLERS ----------------------------------------- //
  const handleClickedImageWithIndex = (photo, index) => {
    handleClickedImage(photo);
    setSelectedPhotoIndex(index);
    setIsEditMode(false);
  };
  const handleNextPhoto = () => {
    if (selectedPhotoIndex < data?.result.length - 1) {
      handleClickedImage(data?.result?.[selectedPhotoIndex + 1]);
      setSelectedPhotoIndex(selectedPhotoIndex + 1);
      setIsEditMode(false);
    }
  };
  const handlePreviousPhoto = () => {
    if (selectedPhotoIndex > 0) {
      handleClickedImage(data?.result?.[selectedPhotoIndex - 1]);
      setSelectedPhotoIndex(selectedPhotoIndex - 1);
      setIsEditMode(false);
    }
  };

  const handleCancelSelectedImage = () => {
    handleClickedImage(null);
    setSelectedPhotoIndex(null);
  };

  const handleUploadButton = () => {
    const requestedData = {
      folder: selectedFolder?.value,
      title: inputValue,
    };
    mutate(requestedData);
  };

  const handleCancelButton = () => {
    resetFields();
  };

  const handleSelectingFolder = (e) => {
    setSelectedFolder(e);
    setFolderError(false);
  };

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
    // console.log({ value: e.target.value }, "input value");
  };

  const handleEditButton = () => {
    setIsEditMode(true);
    setInputValue(selectedPhoto.title);
    const foundFolder = folderOptions.find((folder) => folder.slug === FOLDER_SLUG);
    handleSelectingFolder(foundFolder);
  };

  const handleDeleteButton = () => {
    swal({
      title: "Are you sure?",
      text: `You want to delete "${selectedPhoto?.title || selectedPhoto?.name || selectedPhoto?.filename}" ? `,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willBe) => {
      if (willBe) {
        changeStatusMutation.mutate(selectedPhoto?.id);
      }
    });
  };
  //  ----------------------------------------- FUNCTIONS ----------------------------------------- //
  function selectConvert(data) {
    return data?.map((param) => ({ label: param?.name || param?.title, value: param._id, slug: param?.slug }));
  }

  function resetFields() {
    setSelectedFolder(null);
    setFolderError(true);
    setInputValue("");
    setIsEditMode(false);
  }

  //  ----------------------------------------- GETTING FOLDERS ----------------------------------------- //
  const onSuccessGettingFolders = ({ data }) => {
    setFolderOptions(selectConvert(data?.result));
  };
  // const foldersData = useQueryCustom({
  //   name: "foldersList",
  //   url: getFolders,
  //   onSuccess: onSuccessGettingFolders,
  //   params: { sort: "desc", page: 1, limit: 10, status: 1 },
  // });

  //  ----------------------------------------- UPDATING ----------------------------------------- //
  const onSuccessUpdating = () => {
    toastify.success({ title: "success" });
    resetFields();
    handleCancelSelectedImage();
  };
  const {
    isLoading: isLoadingUpdate,
    data: updatedData,
    mutate,
  } = useMutationCustom({
    url: updatingFile,
    invalidQuery: `galley_get`,
    onSuccess: onSuccessUpdating,
  });

  // ---------------------------------------------- mutation Change Status
  const onSuccessChangeStatusMutation = () => {
    toastify.success({ title: "Success" });
    resetFields();
    handleCancelSelectedImage();
  };

  const changeStatusMutation = useMutationCustom({
    name: "gallery_changeStatus",
    url: changeStatus,
    invalidQuery: `galley_get`,
    onSuccess: onSuccessChangeStatusMutation,
  });

  //  ----------------------------------------- EFFECTS ----------------------------------------- //

  useEffect(() => {
    if (data) {
      const indexOfSelectedPhoto = data?.result?.findIndex((image) => image._id === selectedPhoto?._id);
      setSelectedPhotoIndex(indexOfSelectedPhoto);
      console.log({ indexOfSelectedPhoto }, "indexOfSelectedPhoto");
    }
  }, [data, selectedPhoto]);

  useEffect(() => {
    setTimeout(() => {
      setPreLoad(false);
    }, 1000);

    return () => {
      setPreLoad(true);
    };
  }, []);

  const count = data?.total;
  let showing = page * limit;
  if (showing > count) showing = count;

  //  ----------------------------------------- LOGS ----------------------------------------- //
  console.log({ data, selectedPhoto, selectedPhotoIndex }, "folder by slug data");
  // console.log({ selectedFolder, inputValue }, "folder and input");

  return (
    <div className={styles.mainWrapper}>
      {preLoad ? (
        <div className={styles.preLoad}>
          <Loading />
        </div>
      ) : (
        <div className={`${styles.flexWrapper}`}>
          {data?.result && data?.result?.length ? (
            <div className={`${styles.galleryWrapper} ${selectedPhotoIndex >= 0 && styles.halfSizeWrapper}`}>
              {data?.result?.map((file, index) => {
                let previewElement;
                if (file?.mimetype?.startsWith("image/")) {
                  // For images
                  previewElement = <img src={file.path} alt="gallery item" onClick={() => handleClickedImageWithIndex(file, index)} />;
                } else if (file?.mimetype?.startsWith("video/")) {
                  // For videos
                  previewElement = (
                    <video src={file.path} controls onClick={() => handleClickedImageWithIndex(file, index)} style={{ maxWidth: "300px" }} />
                  );
                } else if (file?.mimetype === "application/pdf") {
                  // For PDFs
                  previewElement = <img src={PdfIcon} alt="pdf item" onClick={() => handleClickedImageWithIndex(file, index)} />;
                }

                return (
                  <div key={file.id} className={styles.imageWrapper}>
                    {previewElement}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles.noDataWrapper}>
              <h2>{t("no_data")}</h2>
            </div>
          )}

          <div className={`${styles.imageDetailsWrapper} ${selectedPhotoIndex >= 0 && styles.showDetailsWrapper}`}>
            <div className={styles.imageDetailsWrapper_imageWrapper}>
              {selectedPhoto?.mimetype?.startsWith("application/pdf") ? (
                <img src={PdfIcon} alt="selected photo" style={{ width: "150px" }} />
              ) : selectedPhoto?.mimetype?.startsWith("image/") ? (
                <img src={selectedPhoto?.path} alt="selected photo" />
              ) : selectedPhoto?.mimetype?.startsWith("video/") ? (
                <video src={selectedPhoto?.path} controls style={{ maxWidth: "300px" }} />
              ) : (
                ""
              )}
              {/* <img src={selectedPhoto?.path} alt="selected photo" /> */}
              <button className={styles.imageDetailsWrapper_button} onClick={handleCancelSelectedImage}>
                <i className="mdi mdi-close" />
              </button>
              <div className={styles.nexPrevBtnWrapper}>
                <button onClick={handlePreviousPhoto}>
                  <i className="mdi mdi-chevron-left" />
                </button>
                <button onClick={handleNextPhoto}>
                  <i className="mdi mdi-chevron-right" />
                </button>
              </div>
            </div>
            {isEditMode ? (
              <div className={styles.extraInformationWrapper}>
                <h3>Edit :</h3>
                <Form.Group className={styles.formGroup}>
                  <div className={"d-flex justify-content-between"}>
                    <label htmlFor={"name"}>Title :</label>
                  </div>
                  <Form.Control
                    id={"name"}
                    name={"name"}
                    type="text"
                    placeholder="Choose a new title"
                    value={inputValue}
                    onChange={handleInputValue}
                  />
                </Form.Group>
                <Form.Group className={"form-group "}>
                  <div className={"d-flex justify-content-between"}>
                    <label htmlFor={"folder"}>Folder * :</label>
                    {folderError && <div className={`error-text`}>Choose please !</div>}
                  </div>
                  <Select
                    className="custom-selector"
                    placeholder={"Choose a folder"}
                    options={folderOptions}
                    value={selectedFolder}
                    onChange={handleSelectingFolder}
                  />
                </Form.Group>

                <div className={styles.buttonsWrapper}>
                  <button className="btn btn-success btn-sm mr-2 btn btn-primary" disabled={isLoadingUpdate} onClick={handleUploadButton}>
                    {isLoadingUpdate ? <Loading size="sm" /> : t("done")}
                  </button>
                  <button className="btn btn-danger btn-sm btn btn-primary" disabled={isLoadingUpdate} onClick={handleCancelButton}>
                    {t("cancel")}
                  </button>
                </div>
              </div>
            ) : (
              <div className={styles.imageDetailsWrapper_detailsWrapper}>
                <div className={styles.textWrapper}>
                  <h4>{selectedPhoto?.filename}</h4>
                  <p>{selectedPhoto?.mimetype}</p>
                </div>
                <button className="btn btn-primary btn-success btn-sm mt-2" onClick={handleModal}>
                  {t("done")}
                </button>
                {!editable ? (
                  <div className={styles.btnWrapper}>
                    {/* <button className="btn btn-primary btn-info btn-sm" onClick={handleEditButton}>
                      {t("edit")}
                    </button> */}
                    <button className="btn btn-primary btn-danger btn-sm" onClick={handleDeleteButton}>
                      {t("delete")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <Row className="mt-5">
        {count ? (
          <Col sm={6} md={6}>
            {`${t("showing")} ${showing || 0} ${t("out_of")} ${count || 0} ${t("entries")}`}
          </Col>
        ) : (
          ""
        )}

        <Col sm={6} md={6}>
          <div className="pagination-container">
            <PaginationM
              {...{
                activePage: page,
                pages: Math.ceil(data?.total / limit),
                limited: 2,
              }}
              onClick={(page) => setPage(page)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default FolderDetail;
