import routes from "operations/routing/routes";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import getDataInObjectDepth from "utils/getDataInObject";
import Add from "./Add";
import { ROUTE_NAME } from "./enum";
import List from "./List";

const AboutUs = () => {
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  return (
    <Routes>
      <Route index element={<Navigate to={newRoute.base + newRoute.list} />} />
      <Route path={newRoute.list} element={<List />} />
      <Route path={newRoute.add} element={<Add />} />
      <Route path={`${newRoute.edit}/:id`} element={<Add />} />
    </Routes>
  );
};

export default AboutUs;
