import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "teamBanner",
    Component: ({ param }) => {
      return (
        <>
          <span className="d-block mb-1">
            <img src={param?.teamBanner} className="image-fluid" />
          </span>
        </>
      );
    },
  },
  {
    header: "map",
    Component: ({ param }) => {
      return (
        <>
          <span className="d-block mb-1">
            <img src={param?.map} className="image-fluid" />
          </span>
        </>
      );
    },
  },
  {
    header: "KVKKTitle",
    Component: ({ param }) => param.KVKKTitle || "Unknown",
  },
  {
    header: "KVKKDescription",
    Component: ({ param }) => {
      return (
        <span id="ellipsis-ex" class="d-inline-block text-truncate" style={{ maxWidth: "150px" }}>
          {param.KVKKDescription || "Unknown"}
        </span>
      );
    },
  },
  {
    header: "b2b",
    Component: ({ param }) => param.b2b || "Unknown",
  },
  {
    header: "Facebook",
    Component: ({ param }) => param.facebook || "Unknown",
  },
  {
    header: "Instagram",
    Component: ({ param }) => param.instagram || "Unknown",
  },
  {
    header: "Whatsapp",
    Component: ({ param }) => param.whatsapp || "Unknown",
  },
  {
    header: "Linkedin",
    Component: ({ param }) => param.linkedin || "Unknown",
  },
  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
];
