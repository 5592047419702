import development from "configs/development.json";
import production from "configs/production.json";

const environment = process.env.NODE_ENV === "production" ? production : development;

export default {
  host: environment.BASE_URL,
  auth: {
    login: "/auth/login",
  },
  refreshToken: "/auth/refresh",
  verify: "/auth/verify-login",
  network: "/network",
  dashboard: "/admin/dashboard",
  // user: {
  //   base: "/member/user",
  //   list: "/member/user/list",
  //   get: "/member/user/get",
  //   get: "/member/user/get",
  //   changeStatus: "/member/user/is-active",
  //   current: "/member/user/current",
  // },
  // admin: {
  //   base: "/member/admin",
  //   list: "/member/admin/list",
  //   get: "/member/admin/get",
  //   get: "/member/admin/get",
  //   changeStatus: "/member/admin/is-active",
  //   current: "/member/admin/current",
  // },
  //---------------------------- new one
  product: {
    base: "/admin/product",
    list: "/admin/product",
    get: "/admin/product",
    delete: "/admin/product",
  },
  productText: {
    base: "/admin/product-text",
    list: "/admin/product-text",
    get: "/admin/product-text",
    delete: "/admin/product-text",
  },
  aboutUs: {
    base: "/admin/about-us",
    list: "/admin/about-us",
    get: "/admin/about-us",
    delete: "/admin/about-us",
  },
  teamCategory: {
    base: "/admin/team-category",
    list: "/admin/team-category",
    get: "/admin/team-category",
    delete: "/admin/team-category",
  },
  team: {
    base: "/admin/team",
    list: "/admin/team",
    get: "/admin/team",
    delete: "/admin/team",
  },
  catalog: {
    base: "/admin/catalog",
    list: "/admin/catalog",
    get: "/admin/catalog",
    delete: "/admin/catalog",
  },
  banners: {
    base: "/admin/banners",
    list: "/admin/banners",
    get: "/admin/banners",
    delete: "/admin/banners",
  },
  missions: {
    base: "/admin/missions",
    list: "/admin/missions",
    get: "/admin/missions",
    delete: "/admin/missions",
  },
  footer: {
    base: "/admin/footer",
    list: "/admin/footer",
    get: "/admin/footer",
    delete: "/admin/footer",
  },
  brands: {
    base: "/admin/brands",
    list: "/admin/brands",
    get: "/admin/brands",
    delete: "/admin/brands",
  },
  contactUs: {
    base: "/admin/contact-us",
    list: "/admin/contact-us",
    get: "/admin/contact-us",
    delete: "/admin/contact-us",
  },

  //---------------------------- new one

  multiplePrice: {
    base: "/admin/hotels/multiple-price-update",
    list: "/admin/hotels/multiple-price-update",
    get: "/admin/hotels/multiple-price-update",
    delete: "/admin/hotels/multiple-price-update",
    changeStatus: "/admin/hotels/multiple-price-update/is-active",
  },
  finance: {
    base: "/admin/dashboard/finance",
    list: "/admin/dashboard/finance",
    get: "/admin/dashboard/finance",
    show: "/admin/dashboard/finance/hotel",
    delete: "/admin/dashboard/finance",
    changeStatus: "/admin/dashboard/finance/is-active",
  },
  financeHotels: {
    base: "/admin/finance/hotels",
    list: "/admin/finance/hotels",
    get: "/admin/finance/hotels",
    delete: "/admin/finance/hotels",
    changeStatus: "/admin/finance/hotels/is-active",
  },
  financeBranches: {
    base: "/admin/finance/branches",
    list: "/admin/finance/branches",
    get: "/admin/finance/branches",
    delete: "/admin/finance/branches",
    changeStatus: "/admin/finance/branches/is-active",
  },
  invoice: {
    base: "/admin/finance/invoice",
    list: "/admin/finance/invoice",
    get: "/admin/finance/invoice",
    delete: "/admin/finance/invoice",
    changeStatus: "/admin/finance/invoice/is-active",
  },
  user: {
    base: "/admin/users",
    list: "/admin/users",
    get: "/admin/users",
    delete: "/admin/users",
    changeStatus: "/admin/users/is-active",
    current: "/users/current",
    profile: "/users/profile",
  },
  order: {
    base: "/admin/orders",
    list: "/admin/orders",
    get: "/admin/orders",
    delete: "/admin/orders",
    statistics: {
      v1: "/admin/orders/statistics",
      v2: "/v2/admin/orders/statistics",
    },
    changeStatus: "/admin/orders/is-active",
    products: "/admin/orders/products",
    fullData: "/admin/orders/order-statistics",
  },
  hotel: {
    base: "/admin/hotels",
    list: "/admin/hotels",
    get: "/admin/hotels",
    delete: "/admin/hotels",
    changeStatus: "/admin/hotels/is-active",
  },
  menu: {
    base: "/admin/hotels/menu",
    list: "/admin/hotels/menu",
    get: "/admin/hotels/menu",
    delete: "/admin/hotels/menu",
    copy: "/admin/hotels/menu/copy-menu",
    addAllItem: "/admin/menus/add-item-all-menus",
  },
  setting: {
    base: "/admin/setting",
    list: "/admin/setting",
    get: "/admin/setting",
    delete: "/admin/setting",
    changeStatus: "/admin/setting/is-active",
  },
  language: {
    base: "/admin/languages",
    list: "/admin/languages",
    get: "/admin/languages",
    delete: "/admin/languages",
    changeStatus: "/admin/languages/is-active",
  },
  region: {
    base: "/admin/regions",
    list: "/admin/regions",
    get: "/admin/regions",
    delete: "/admin/regions",
    changeStatus: "/admin/regions/is-active",
  },
  branch: {
    base: "/admin/branches",
    list: "/admin/branches",
    get: "/admin/branches",
    delete: "/admin/branches",
    changeStatus: "/admin/branches/is-active",
  },

  hour: {
    base: "/admin/hour",
    list: "/admin/hour",
    get: "/admin/hour",
    delete: "/admin/hour",
  },
  deliveries: {
    base: "/admin/deliveries",
    list: "/admin/deliveries",
    get: "/admin/deliveries",
    delete: "/admin/deliveries",
  },
  menus: {
    base: "/admin/menus",
    list: "/admin/menus",
    get: "/admin/menus",
    delete: "/admin/menus",
  },
  activities: {
    base: "/admin/activities",
    list: "/admin/activities",
    get: "/admin/activities",
    delete: "/admin/activities",
  },
  places: {
    base: "/admin/places",
    list: "/admin/places",
    get: "/admin/places",
    delete: "/admin/places",
  },

  role: {
    base: "/role",
    list: "/role/list",
    get: "/role/get",
    delete: "/role/get",
    changeStatus: "/role/is-active",
  },
  newsletter: {
    base: "/newsletter",
    list: "/newsletter/list",
    get: "/newsletter/get",
    delete: "/newsletter/get",
    changeStatus: "/newsletter/is-active",
  },
  permission: {
    base: "/permission",
    list: "/permission/list",
  },
  // ------------------------------------------------- blogs
  category: {
    base: "/admin/product-categories",
    list: "/admin/product-categories",
    get: "/admin/product-categories",
    delete: "/admin/product-categories",
  },
  news: {
    base: "/admin/news",
    list: "/admin/news",
    get: "/admin/news",
    delete: "/admin/news",
  },
  tag: {
    base: "/blog/tag",
    list: "/blog/tag/list",
    get: "/blog/tag/get",
    delete: "/blog/tag/get",
    changeStatus: "/blog/tag/is-active",
  },
  post: {
    base: "/blog/post",
    list: "/blog/post/list",
    get: "/blog/post/get",
    delete: "/blog/post/get",
    changeStatus: "/blog/post/is-active",
  },
  // ------------------------------------------------- media
  folder: {
    base: "/media/folder",
    list: "/media/folder/list",
    get: "/media/folder/get",
    delete: "/media/folder/get",
    getBySlug: "/media/folder/get/slug",
    changeStatus: "/media/folder/is-active",
  },
  gallery: {
    base: "/admin/gallery",
    list: "/admin/gallery",
    get: "/admin/gallery",
    delete: "/admin/gallery",
    changeStatus: "/admin/gallery",
  },
  files: {
    base: "/admin/files",
    list: "/admin/files",
    get: "/admin/files",
    delete: "/admin/files",
    changeStatus: "/admin/files",
  },
};
