import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
    name: "name",
    image: "image",
    isNewCategory: "isNewCategory",
    categoryType: "categoryType",
    isActive: "isActive",
    parent: "parent",
    title: "title",
    description: "description",
    translations: "translations",
    translate_title: "translate_title",
    translate_language: "translate_language",
    translate_description: "translate_description",
    listOrderNumber: "listOrderNumber",
    translate_name: "translate_name",
    translate_language: "translate_language",
    language: "language",
    newsDate: "newsDate",
};

const translations = yup
    .object({
        [fieldNames.translate_title]: yup.string().required(ERRORS.pleaseEnterYour("title")).label("Title"),
        [fieldNames.translate_description]: yup.string().required(ERRORS.pleaseEnterYour("description")).label("Description"),
        [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
    })
    .defined();

export const validation = (URL_ID) =>
    yup.object().shape({
        [fieldNames.title]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.title)).label(fieldNames.title),
        [fieldNames.description]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.description)).label(fieldNames.description),
        [fieldNames.newsDate]: yup.date().required(ERRORS.pleaseEnterYour(fieldNames.newsDate)).label(fieldNames.newsDate),
        [fieldNames.image]: yup.lazy((value) => {
            switch (typeof value) {
                case "object":
                    return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for object
                case "string":
                    return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for string
                default:
                    return yup.mixed(); // here you can decide what is the default
            }
        }),
        [fieldNames.translations]: yup.array().of(translations),
    });
