import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  description: "description",
  "image": "image",
  listOrderNumber: "listOrderNumber",

  translations: "translations",

  translate_language: "translate_language",
  translate_description: "translate_description",

  language: "language",
};

const translations = yup
  .object({
    [fieldNames.translate_description]: yup.string().required(ERRORS.pleaseEnterYour("description")).label("description"),
    [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
  })
  .defined();

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.description]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.description)).label(fieldNames.description),
    [fieldNames.listOrderNumber]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.listOrderNumber)).label(fieldNames.listOrderNumber),
    [fieldNames.image]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),

    [fieldNames.translations]: yup.array().of(translations),
  });
