import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldText from "components/FieldText";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";

const Add = () => {
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [type, setType] = useState(null);
  const { t } = useTranslation();

  // ------------------------------------ route
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  const typeOptions = [
    { label: "User", value: "USER" },
    { label: "Admin", value: "ADMIN" },
  ];

  // ----------------------------------------------------------------------------- Fetching Functions
  const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
  const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
  const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);

  const navigation = useNavigate();
  let { id, hotel } = useParams();
  const URL_ID = id ? id : "";

  // ----------------------------------------------------------------------------- Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
  });

  // ----------------------------------------------------------------------------- Mutation
  const onSuccessMutating = () => {
    toastify.success({ title: "success" });
    navigation(newRoute.base);
  };
  const { isLoading, mutate } = useMutationCustom({
    url: URL_ID ? updating : creating,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: onSuccessMutating,
  });

  // ----------------------------------------------------------------------------- Query By ID
  const onSuccessDataById = ({ data }) => {
    let params = { ...data };

    reset({
      ...params,
    });
  };
  const { data: dataById } = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: getById,
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const values = getValues();

    const permissions = [];

    if (values?.role?.value == "admin") {
      for (const key in values?.permissions) {
        const curr = values?.permissions[key];
        if (curr) permissions.push(key);
      }
    }

    const requestData = {
      ...values,
      password: values.password || undefined,
    };
    console.log({ requestData });
    mutate(requestData);
  };

  // ----------------------------------------------------------------------------- Constance
  const dispatch = useDispatch();
  const button = {
    title: `${t("back_to")} ${t(COMPONENT_NAMES[0])} ${t("list")}`,
    link: newRoute.base + newRoute.list,
  };

  const breadcrumbs = [
    // { title: t("dashboard"), link: routes.dashboard },
    { title: COMPONENT_NAMES[0], link: newRoute.base },
    {
      title: URL_ID ? `${t("edit")}/ ${dataById?.data?.name} ` : "add",
    },
  ];
  // ---------------------------------------------- EFFECTS
  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, [dataById]);
  // ----------------------------------------------------------------------------- Log
  const values = getValues();

  const valuesGet = getValues();
  const permissions = [];
  for (const key in valuesGet?.permissions) {
    const curr = valuesGet?.permissions[key];
    if (curr) permissions.push(key);
  }

  return (
    <div>
      <PageTitle
        title={URL_ID ? t(`editAuthor`, { author: t(COMPONENT_NAMES[0]) }) : t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) })}
        button={button}
      />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("basic_information")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        {...{
                          name: fieldNames.name,
                          register,
                          placeholder: "name",
                          label: "name",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        {...{
                          name: fieldNames.email,
                          register,
                          placeholder: "email",
                          label: "email",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        type={showPass ? "text" : "password"}
                        {...{
                          name: fieldNames.password,
                          register,
                          placeholder: "password",
                          label: "password",
                          errors,
                          show: showPass,
                          setShow: setShowPass,
                        }}
                      />
                      <FieldText
                        require
                        type={showConfirmPass ? "text" : "password"}
                        {...{
                          name: fieldNames.confirm_password,
                          register,
                          placeholder: "confirmPassword",
                          label: "confirmPassword",
                          errors,
                          show: showConfirmPass,
                          setShow: setShowConfirmPass,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="row">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{t("actions")}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};

export default Add;
