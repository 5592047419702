import CheckBox from "components/CheckBox";
import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "name",
    Component: ({ param }) => param.name || "Unknown",
  },
  {
    header: "email",
    Component: ({ param }) => param.email || "Unknown",
  },
  {
    header: "phone",
    Component: ({ param }) => param.phone || "Unknown",
  },
  {
    header: "subject",
    Component: ({ param }) => param.subject || "Unknown",
  },
  {
    header: "isRead",
    Component: ({ param }) => <CheckedComponent checked={param?.isRead} />,
  },
  {
    header: "message",
    Component: ({ param }) => {
      return (
        <span id="ellipsis-ex" class="d-inline-block text-truncate" style={{ maxWidth: "150px" }}>
          {param.text || "Unknown"}
        </span>
      );
    },
  },

  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, active: { show: true }, disable: { edit: true, delete: true } }} />,
  },
];
