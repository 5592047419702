import AboutUs from "containers/AboutUs";
import Banners from "containers/Banners";
import BaseDashboard from "containers/BaseDashboard";
import Brands from "containers/Brands";
import Catalogs from "containers/Catalog";
import Dashboard from "containers/Dashboard";
import Footer from "containers/Footer";
import Gallery from "containers/Gallery";
import Language from "containers/Language";
import News from "containers/News";
import Products from "containers/ProductNew";
import ProductText from "containers/ProductText";
import User from "containers/User";
import { PERMISSIONS } from "enumerations";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import routes from "./routes";
import TeamCategory from "containers/TeamCategory";
import Team from "containers/Team";
import Setting from "containers/Setting";
import ContactUs from "containers/ContactUs";
import MissionsVisions from "containers/MissionsVisions";
import GalleryList from "containers/GalleryList";

const Buttons = lazy(() => import("containers/basic-ui/Buttons"));
const Dropdowns = lazy(() => import("containers/basic-ui/Dropdowns"));
const Typography = lazy(() => import("containers/basic-ui/Typography"));
const BasicElements = lazy(() => import("containers/form-elements/BasicElements"));
const BasicTable = lazy(() => import("containers/tables/BasicTable"));
const Mdi = lazy(() => import("components/icons/Mdi"));
const ChartJs = lazy(() => import("containers/charts/ChartJs"));
const Error404 = lazy(() => import("containers/error-pages/Error404"));
const Error500 = lazy(() => import("containers/error-pages/Error500"));
const Register1 = lazy(() => import("containers/user-pages/Register"));
const Lockscreen = lazy(() => import("containers/user-pages/Lockscreen"));
const BlankPage = lazy(() => import("containers/general-pages/BlankPage"));

// -------------------- containers

const routing = [
  { path: "/", exact: true, element: <Navigate to={routes.product.base} /> },

  {
    path: routes.dashboard,
    element: <Dashboard />,
    name: "dashboard",
    permission: true,
    // permissions: ["ALL"],
  },

  {
    path: "/base-dashboard",
    element: <BaseDashboard />,
    name: "base-dashboard",
    permission: true,
    // permissions: ["ALL"],
  },

  // ------------------------- new ones-------------------------------
  { path: routes.user.all, element: <User />, permission: true, name: "users" },
  { path: routes.news.all, element: <News />, permission: true, name: "news" },
  { path: routes.catalog.all, element: <Catalogs />, permission: true, name: "catalog" },
  { path: routes.product.all, element: <Products />, permission: true, name: "products" },
  { path: routes.productText.all, element: <ProductText />, permission: true, name: "productText" },
  { path: routes.about_us.all, element: <AboutUs />, permission: true, name: "aboutUs" },
  { path: routes.banners.all, element: <Banners />, permission: true, name: "banners" },
  { path: routes.brands.all, element: <Brands />, permission: true, name: "brands" },
  { path: routes.footer.all, element: <Footer />, permission: true, name: "footer" },
  { path: routes.gallery.all, element: <GalleryList />, permission: true, name: "files" },
  { path: routes.language.all, element: <Language />, permission: true, name: "languages" },
  { path: routes.team.all, element: <Team />, permission: true, name: "team" },
  { path: routes.setting.all, element: <Setting />, permission: true, name: "setting" },
  { path: routes.team_category.all, element: <TeamCategory />, permission: true, name: "teamCategory" },
  { path: routes.contactUs.all, element: <ContactUs />, permission: true, name: "contactUs" },
  { path: routes.missions.all, element: <MissionsVisions />, permission: true, name: "missions" },

  // ------------------------- new ones-------------------------------
  { path: "/basic-ui/buttons", element: <Buttons /> },
  { path: "/basic-ui/dropdowns", element: <Dropdowns /> },
  { path: "/basic-ui/typography", element: <Typography /> },
  { path: "/form-Elements/basic-elements", element: <BasicElements /> },
  { path: "/tables/basic-table", element: <BasicTable /> },
  { path: "/icons/mdi", element: <Mdi />, permissions: [PERMISSIONS.USER] },
  { path: "/charts/chart-js", element: <ChartJs /> },
  { path: "/user-pages/register-1", element: <Register1 /> },
  { path: "/user-pages/lockscreen", element: <Lockscreen /> },
  { path: "/error-pages/error-404", element: <Error404 /> },
  { path: "/error-pages/error-500", element: <Error500 /> },
  { path: "/general-pages/blank-page", element: <BlankPage /> },
];

export default routing;
