const compareTranslate = (t, param = {}) => {
  if (typeof param === "string") return t(param);
  const { text, params } = param;
  const properties = {};
  for (const key in params) {
    properties[key] = t(params[key]);
  }
  // console.log({ text, params, param, properties });
  return t(text, properties);
};

export default compareTranslate;
