import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldText from "components/FieldText";
import GalleryComponent from "components/gallery/GalleryComponent";
import GalleryModal from "components/gallery/GalleryModal";
import LanguageSelector from "containers/Language/LanguageSelector";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import BrandsSelector from "containers/Brands/BrandsSelector";

const Add = () => {
  const { t } = useTranslation();

  // ------------------------------------ route
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

  // ----------------------------------------------------------------------------- Fetching Functions
  const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
  const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
  const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
  // const getRoles = async () => await axiosClient().get(api["role"].list);

  const navigation = useNavigate();
  let { id } = useParams();
  const URL_ID = id ? id : "";
  // ----------------------------------------------------------------------------- Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
  });

  // ----------------------------------------------------------------------------- Mutation
  const onSuccessMutating = () => {
    toastify.success({ title: "success" });
    navigation(newRoute.base);
  };
  const { isLoading, data, mutate } = useMutationCustom({
    url: URL_ID ? updating : creating,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: onSuccessMutating,
  });

  // ----------------------------------------------------------------------------- Query By ID
  const onSuccessDataById = ({ data }) => {
    let params = { ...data };
    console.log({ params });
    handleSelectThumbnail(params?.image);
    handleSelectThumbnailBanner(params?.banner);
    reset({
      ...params,
      brands: params?.brands?.map((x) => ({ label: x.name, value: x.id })),
      translations: params?.translations?.map((x) => ({
        translate_name: x.name,
        translate_language: { label: x.language, value: x.language },
      })),
    });
  };
  const { data: dataById } = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: getById,
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const { translations, ...values } = getValues();
    console.log({ translations, values });
    const requestData = {
      name: values.name,
      slug: values.slug,
      image: values.image?.path || values.image,
      banner: values?.banner?.path || values.banner,
      brandsId: values?.brands?.map((val) => val?.value),
      listOrderNumber: values?.listOrderNumber,
      translations: translations?.map((x) => ({
        language: x.translate_language?.value || x.translate_language,
        name: x.translate_name,
      })),
    };
    console.log({ requestData });
    mutate(requestData);
  };

  // ----------------------------------------------------------------------------- Constance
  const dispatch = useDispatch();
  const button = {
    title: `${t("back_to")} ${t(COMPONENT_NAMES[0])} ${t("list")}`,
    link: newRoute.base + newRoute.list,
  };

  const breadcrumbs = [
    // { title: t("dashboard"), link: routes.dashboard },
    { title: COMPONENT_NAMES[0], link: newRoute.base },
    {
      title: URL_ID ? `${t("edit")}/ ${dataById?.data?.name}` : "add",
    },
  ];
  // ---------------------------------------------- EFFECTS
  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, [dataById]);
  // ----------------------------------------------------------------------------- Log
  const values = getValues();
  console.log({ dataById, values }, "Add");
  console.log({ URL_ID });
  console.log({ errors }, "errors");

  // ----------------------------------------------------------------------------- UseEffects

  //  ----------------------------------------- GALLERY ----------------------------------------- //
  const [showModal, setShowModal] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const handleGalleryModal = () => {
    setShowModal(!showModal);
  };
  const handleSelectThumbnail = (thumbnail) => {
    setValue(fieldNames.image, thumbnail, {
      shouldValidate: true,
    });
    setThumbnail(thumbnail);
  };
  //  ----------------------------------------- BANNER ----------------------------------------- //
  const [showModalBanner, setShowModalBanner] = useState(false);
  const [thumbnailBanner, setThumbnailBanner] = useState(null);
  const handleGalleryModalBanner = () => {
    setShowModalBanner(!showModalBanner);
  };
  const handleSelectThumbnailBanner = (thumbnail) => {
    setValue(fieldNames.banner, thumbnail, {
      shouldValidate: true,
    });
    setThumbnailBanner(thumbnail);
  };

  const defaultData = { title: "", description: "" };

  const addNewtranslation = () => {
    const values = getValues();
    const newData = values[fieldNames.translations] ? [...values[fieldNames.translations], { ...defaultData }] : [{ ...defaultData }];
    console.log({ newData });
    setValue(fieldNames.translations, newData);
  };

  return (
    <div>
      <GalleryModal
        {...{
          showModal: showModalBanner,
          handleModal: handleGalleryModalBanner,
          handleClickedImage: handleSelectThumbnailBanner,
          selectedPhoto: thumbnailBanner,
        }}
      />
      <GalleryModal
        {...{
          showModal,
          handleModal: handleGalleryModal,
          handleClickedImage: handleSelectThumbnail,
          selectedPhoto: thumbnail,
        }}
      />
      <PageTitle title={URL_ID ? `${t("edit")}  ${t(COMPONENT_NAMES[0])}` : `${t("add")}  ${t(COMPONENT_NAMES[0])}`} button={button} />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("basic_information")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        {...{
                          name: fieldNames.name,
                          register,
                          placeholder: "name",
                          label: "name",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.slug,
                          register,
                          placeholder: "slug",
                          label: "slug",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.listOrderNumber,
                          register,
                          placeholder: "listOrderNumber",
                          label: "listOrderNumber",
                          errors,
                          type: "number",
                        }}
                      />
                      <BrandsSelector
                        {...{
                          name: `brands`,
                          placeholder: "brands",
                          label: "brands",
                          errors,
                          control,
                        }}
                      />
                      <GalleryComponent
                        isRequired
                        {...{
                          handleModal: handleGalleryModal,
                          selectedPhoto: thumbnail,
                          label: "image",
                          name: fieldNames.image,
                          Controller,
                          control,
                          errors,
                          register,
                        }}
                      />
                      <GalleryComponent
                        isRequired
                        {...{
                          handleModal: handleGalleryModalBanner,
                          selectedPhoto: thumbnailBanner,
                          label: "banner",
                          name: fieldNames.banner,
                          Controller,
                          control,
                          errors,
                          register,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 border p-4 mt-4">
              <div className="">
                <Row className="b-1">
                  <Col lg={6}>
                    <h2 className="">{t("translations")}</h2>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    <Button onClick={addNewtranslation} className={"d-flex align-items-center"} size="sm">
                      <div className={"gallery-add-title"}>{t("add_translate")}</div>
                      <div className={"ml-1"}>+</div>
                    </Button>
                  </Col>
                </Row>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form"></div>
                    <Controller
                      name={fieldNames.translations}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        console.log({ value });

                        return Children.toArray(
                          value?.map?.((locale, index) => {
                            const values2 = getValues();
                            const removeClick = (index) => {
                              let values = getValues();
                              if (!values[fieldNames.translations]) return;
                              const newValues = values[fieldNames.translations].filter((param, i) => i !== index);
                              setValue(fieldNames.translations, newValues);
                            };

                            return (
                              <Row className="translation-row" key={value?.name || index}>
                                {index >= 0 ? (
                                  <>
                                    <Col lg={10}>
                                      <h5>{`${t("translation")} ${index + 1}`}</h5>
                                    </Col>
                                    <Col lg={2}>
                                      <div>
                                        <Button
                                          color="warning"
                                          className={"d-flex align-items-center"}
                                          size="sm"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            removeClick(index);
                                          }}
                                        >
                                          {t("remove")}
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col lg={12}>
                                      <hr style={{ marginBottom: 0 }} />
                                    </Col>
                                  </>
                                ) : (
                                  ""
                                )}

                                <FieldText
                                  require
                                  {...{
                                    name: `translations[${index}].translate_name`,
                                    placeholder: "name",
                                    label: "name",
                                    error: errors.translations?.[index]?.translate_name,
                                    control,
                                  }}
                                />
                                <LanguageSelector
                                  {...{
                                    name: `translations[${index}].translate_language`,
                                    label: "language",
                                    error: errors.translations?.[index]?.translate_language,
                                    control,
                                  }}
                                />
                              </Row>
                            );
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md="4">
            <div className="row">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{t("actions")}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};

export default Add;
