import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
  slug: "slug",
  image: "image",
  translations: "translations",
  translate_name: "translate_name",
  translate_language: "translate_language",
  brands: "brands",
  banner: "banner",
  listOrderNumber: "listOrderNumber",
};

const translations = yup
  .object({
    [fieldNames.translate_name]: yup.string().required(ERRORS.pleaseEnterYour("name")).label("Name"),
    [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
  })
  .defined();

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
    [fieldNames.slug]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.slug)).label(fieldNames.slug),
    [fieldNames.listOrderNumber]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.listOrderNumber)).label(fieldNames.listOrderNumber),
    [fieldNames.brands]: yup.array().required(ERRORS.pleaseEnterYour(fieldNames.brands)).label(fieldNames.brands),
    [fieldNames.image]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),
    [fieldNames.banner]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.banner)).label(fieldNames.banner); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.banner)).label(fieldNames.banner); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),
    [fieldNames.translations]: yup.array().of(translations),
  });
