import { useEffect } from "react";
import "./App.scss";
import AppRoutes from "./AppRoutes";
// import actionRefreshToken from "redux/store/refreshToken/action";
import WithErrorHandler from "components/WithErrorHandler";
import Login from "containers/Login";
import { activate } from "lib/NoSleep";
import routes from "operations/routing/routes";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const App = () => {
  const token = useSelector((state) => state.token);
  const adminState = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const cookieToken = cookies.get("accessToken");
  console.log({ cookieToken, token });

  // useEffect(() => {
  //   if (token.data) {
  //     // dispatch(adminAction.request());
  //     localStorage.removeItem("state");
  //   }
  // }, []);
  useEffect(() => {
    activate();
  }, []);

  return (
    <WithErrorHandler>
      <Routes>
        <Route path={routes.login} element={<Login />} />
        <Route path={"*"} element={token?.data && cookieToken ? <AppRoutes /> : <Navigate to={"/login"} />} />
      </Routes>
    </WithErrorHandler>
  );
};

export default App;
