import React from "react";
import Spinner from "react-bootstrap/Spinner";
const Loading = ({ size }) => {
  return (
    <Spinner animation="grow" role="status" size={size}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};

export default Loading;
