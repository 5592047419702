import actionTypes from "./actionTypes";
import initialState from "./initialState"

import { failureFunc, initialFunc, receiveFunc, requestFunc } from "./operation"


const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case actionTypes.TOKEN_REQUEST:
      return requestFunc(state, action)
    case actionTypes.TOKEN_RECEIVE:
      return receiveFunc(state, action)
    case actionTypes.TOKEN_FAILURE:
      return failureFunc(state, action)
    case actionTypes.TOKEN_INITIAL:
      return initialFunc(state, action)
    default:
      return state
  }
}

export default reducer;