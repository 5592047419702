import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  banner: "banner",
  missionTitle: "missionTitle",
  missionSubTitle: "missionSubTitle",
  missionDescription: "missionDescription",
  visionTitle: "visionTitle",
  visionSubTitle: "visionSubTitle",
  visionDescription: "visionDescription",

  translations: "translations",
  translate_language: "translate_language",
  translate_missionTitle: "translate_missionTitle",
  translate_missionSubTitle: "translate_missionSubTitle",
  translate_missionDescription: "translate_missionDescription",
  translate_visionTitle: "translate_visionTitle",
  translate_visionSubTitle: "translate_visionSubTitle",
  translate_visionDescription: "translate_visionDescription",
};

const translations = yup
  .object({
    [fieldNames.translate_missionTitle]: yup.string().required(ERRORS.pleaseEnterYour("missionTitle")).label("missionTitle"),
    [fieldNames.translate_missionSubTitle]: yup.string().required(ERRORS.pleaseEnterYour("missionSubTitle")).label("missionSubTitle"),
    [fieldNames.translate_missionDescription]: yup.string().required(ERRORS.pleaseEnterYour("missionDescription")).label("missionDescription"),
    [fieldNames.translate_visionTitle]: yup.string().required(ERRORS.pleaseEnterYour("visionTitle")).label("visionTitle"),
    [fieldNames.translate_visionSubTitle]: yup.string().required(ERRORS.pleaseEnterYour("visionSubTitle")).label("visionSubTitle"),
    [fieldNames.translate_visionDescription]: yup.string().required(ERRORS.pleaseEnterYour("visionDescription")).label("visionDescription"),
    [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
  })
  .defined();

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.missionTitle]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.missionTitle)).label(fieldNames.missionTitle),
    [fieldNames.missionSubTitle]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.missionSubTitle)).label(fieldNames.missionSubTitle),
    [fieldNames.missionDescription]: yup
      .string()
      .required(ERRORS.pleaseEnterYour(fieldNames.missionDescription))
      .label(fieldNames.missionDescription),
    [fieldNames.visionTitle]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.visionTitle)).label(fieldNames.subDescription),
    [fieldNames.visionSubTitle]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.visionSubTitle)).label(fieldNames.visionSubTitle),
    [fieldNames.visionDescription]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.visionDescription)).label(fieldNames.visionDescription),
    [fieldNames.banner]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.banner)).label(fieldNames.banner); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.banner)).label(fieldNames.banner); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),
    [fieldNames.translations]: yup.array().of(translations),
  });
