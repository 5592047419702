import actionTypes from "./actionTypes"

export default {
    request: (params, ctx) => ({
        type: actionTypes.TOKEN_REQUEST,
        params,
        ctx
    }),
    receive: (data, ctx) => ({
        type: actionTypes.TOKEN_RECEIVE,
        data,
        ctx
    }),
    initial: (ctx) => ({
        type: actionTypes.TOKEN_INITIAL,
        ctx
    }),
    failure: (errorMessage) => ({
        type: actionTypes.TOKEN_FAILURE,
        errorMessage
    }),
    accessTokenCheck: (ctx) => ({
        type: actionTypes.TOKEN_CHECK,
        ctx
    }),
}