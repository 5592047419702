import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "banner",
    Component: ({ param }) => {
      return (
        <>
          <span className="d-block mb-1">
            <img src={param?.banner} className="image-fluid" />
          </span>
        </>
      );
    },
  },

  {
    header: "missionTitle",
    Component: ({ param }) => {
      return <span>{param.missionTitle || "Unknown"}</span>;
    },
  },
  {
    header: "missionSubTitle",
    Component: ({ param }) => {
      return <span>{param.missionSubTitle || "Unknown"}</span>;
    },
  },
  {
    header: "missionDescription",
    Component: ({ param }) => {
      return (
        <span id="ellipsis-ex" class="d-inline-block text-truncate" style={{ maxWidth: "150px" }}>
          {param.missionDescription || "Unknown"}
        </span>
      );
    },
  },
  {
    header: "visionTitle",
    Component: ({ param }) => {
      return <span>{param.visionTitle || "Unknown"}</span>;
    },
  },
  {
    header: "visionSubTitle",
    Component: ({ param }) => {
      return <span>{param.visionSubTitle || "Unknown"}</span>;
    },
  },
  {
    header: "visionDescription",
    Component: ({ param }) => {
      return (
        <span id="ellipsis-ex" class="d-inline-block text-truncate" style={{ maxWidth: "150px" }}>
          {param.visionDescription || "Unknown"}
        </span>
      );
    },
  },

  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
];
