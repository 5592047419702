export default {
  home: `/home`,
  login: `/login`,
  verify: `/verify`,
  dashboard: "/dashboard",
  file: {
    base: `/file`,
    list: `/file/list`,
    add: `/file/add`,
    edit: `/file/edit`,
  },

  user: {
    base: "/user",
    all: "/user/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
    inactive: "edit/inactive",
    active: "edit/active",
    kyc: {
      base: "/user/kyc",
      list: "/user/kyc/list",
      add: "/user/kyc/add",
      edit: "/user/kyc/edit",
    },
    device: {
      base: "/user/device",
      list: "/user/device/list",
      add: "/user/device/add",
      edit: "/user/device/edit",
    },
    level: {
      base: "/user/level",
      list: "/user/level/list",
      add: "/user/level/add",
      edit: "/user/level/edit",
    },
  },
  multiplePrice: {
    base: "/multiple-price",
    all: "/multiple-price/*",
    list: "/list",
    add: "/add",
  },
  finance: {
    base: "/finance/info",
    all: "/finance/info/*",
    list: "/list",
    show: "/show",
  },
  invoice: {
    base: "/finance/invoice",
    all: "/finance/invoice/*",
    list: "/list",
    show: "/show",
  },
  financeBranches: {
    base: "/finance/branches",
    all: "/finance/branches/*",
    list: "/list",
    show: "/show",
  },
  financeHotels: {
    base: "/finance/hotels",
    all: "/finance/hotels/*",
    list: "/list",
    show: "/show",
  },
  language: {
    base: "/language",
    all: "/language/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  order: {
    base: "/order",
    all: "/order/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  setting: {
    base: "/setting",
    all: "/setting/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  hotel: {
    base: "/hotel",
    all: "/hotel/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
    user: {
      base: "/hotel/user",
      all: "/user/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
      w: {
        base: "/user",
        list: "/list",
        add: "/add",
        edit: "/edit",
      },
    },
    menu: {
      base: "/hotel/menu",
      all: "/menu/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
      copy: "/copy-menu",
      w: {
        base: "/menu",
        list: "/list",
        add: "/add",
        edit: "/edit",
        copy: "/copy-menu",
      },
    },
  },

  region: {
    base: `/region`,
    all: "/region/*",
    list: `/region/list`,
    add: `/region/add`,
    edit: `/region/edit`,
  },
  branch: {
    base: "/branch",
    all: "/branch/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  product: {
    base: "/product",
    all: "/product/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  hour: {
    base: "/hour",
    all: "/hour/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  activities: {
    base: "/activities",
    all: "/activities/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  deliveries: {
    base: "/deliveries",
    all: "/deliveries/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  menus: {
    base: "/menus",
    all: "/menus/*",
    list: "/list",
    add: "/add",
    addItem: "/add-item",
    edit: "/edit",
  },
  places: {
    base: "/places",
    all: "/places/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  gallery: {
    base: "/gallery",
    all: "/gallery/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  category: {
    base: "/category",
    all: "/category/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  news: {
    base: "/news",
    all: "/news/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  catalog: {
    base: "/catalog",
    all: "/catalog/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  product: {
    base: "/product",
    all: "/product/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  productText: {
    base: "/product_text",
    all: "/product_text/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  about_us: {
    base: "/about_us",
    all: "/about_us/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  team_category: {
    base: "/category_team",
    all: "/category_team/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  team: {
    base: "/team",
    all: "/team/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  banners: {
    base: "/banners",
    all: "/banners/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  footer: {
    base: "/footer",
    all: "/footer/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  brands: {
    base: "/brands",
    all: "/brands/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },

  newsletter: {
    base: "/newsletter",
    all: "/newsletter/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  missions: {
    base: "/missions",
    all: "/missions/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
  },
  contactUs: {
    base: "/contact_us",
    all: "/contact_us/*",
    list: "/list",
    add: "/add",
    edit: "/edit",
    show: "/show",
  },

  blog: {
    base: "/blog",
    all: "/blog/*",
    post: {
      base: "/blog/post",
      all: "/blog/post/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
    category: {
      base: "/blog/category",
      all: "/blog/category/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
    tag: {
      base: "/blog/tag",
      all: "/blog/tag/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
  },
  media: {
    base: "/media",
    all: "/media/*",
    list: "/list",
    folder: {
      base: "/media/folder",
      all: "/media/folder/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
    gallery: {
      base: "/media/gallery",
      all: "/media/gallery/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
      folderName: "/media/gallery/:folderName",
    },
  },
};
