const baseStrings = {
    translation: {
        name: "Name",
        dashboard: "Dashboard",
        order: "Order",
        product: "Product",
        category: "Category",
        user: "User",
        branch: "Branch",
        hotel: "Hotel",
        gallery: "Gallery",
        language: "Language",
        setting: "Setting",
        allStatus: "All Status",
        delete: "Delete",
        add: "Add",
        edit: "Edit",
        from: "From",
        to: "To",
        submit: "Submit",
        orderLists: "Order Lists",
        paymentType: "Payment Type",
        roomNumber: "Room Number",
        products: "Products",
        totalPrice: "Total Price",
        status: "Status",
        note: "Note",
        createdAt: "Created At",
        action: "Action",
        search: "Search",
        newest: "Newest",
        image: "Image",
        price: "Price",
        translations: "Translations",
        stock: "Stock",
        integrationCode: "Integration Code",
        isFastDelivery: "Fast Delivery",
        isNewProduct: "New Product",
        listOrderNumber: "List Order",
        addProduct: "Add Product",
        active: "Active",
        parent: "Parent",
        categoryType: "Category Type",
        isNewCategory: "New Category",
        email: "Email",
        emailNotification: "Email Notification",
        phoneNotification: "Phone Notification",
        role: "Role",
        commercialTitle: "Commercial Title",
        currentNotWorkingReason: "Not Working Reason",
        startDate: "Start Date",
        newsDate: "Date",
        endDate: "End Date",
        address: "Adres",
        panelName: "Panel Name",
        taxAdministration: "Tax Administration",
        taxNo: "Tax No",
        code: "Code",
        isSystemWorking: "System Working",
        systemStartTime: "System Start Time",
        systemEndTime: "System End Time",
        qrCode: "QR Code",
        users: "Users",
        menues: "Menues",
        menue: "Menue",
        disable: "Disable",
        defaultLanguage: "Default Language",
        drink: "Drink",
        drinkInvoice: "Drink Invoice",
        foodInvoice: "Food Invoice",
        formal: "Formal",
        orderLimit: "Order Limit",
        phoneNumber: "Phone Number",
        roomCount: "Room Count",
        serviceCharge: "Service Charge",
        allOrders: "All Orders",
        dailyOrders: "Daily Orders",
        monthlyOrders: "Monthly Orders",
        total: "Total",
        finance: "Finance",
        totalAmount: "Total Amount",
        commission: "Commission",
        exportExcel: "Donwload As Excel",
        all: "all",
        multiplePrice: "Multiple Price",
        updateAuthor: "Update {{author}}",
        addAuthor: "Add {{author}}",
        editAuthor: "Edit {{author}}",
        listAuthor: "{{author}} list",
        listsAuthor: "{{author}} lists",
        selectSearch: "you can search your item",
        orderCount: "Order Count",
        totalCommission: "Total Komisyon",
        actions: "Actions",
        startTime: "Start Time",
        endTime: "End Time",
        isOrderLimit: "Sipariş Limiti",
        menus: "Menus",
        delivery: "Delivery",
        deliveries: "Deliveries",
        places: "Places",
        orderNumber: "Order Number",
        operation: "Operation",
        operationSide: "Operation Side",
        operationUnit: "Operation Unit",
        oldest: "Oldest",
        selectDate: "Select Date",
        refund: "Refund",
        canceled: "Canceled",
        paymentSuccess: "Payment Success",
        paymentFailed: "Payment Failed",
        waiting: "Waiting",
        sended: "Sended",
        preparing: "Preparing",
        delivered: "Delivered",
        onWay: "On Way",
        totalOrders: "Total Orders",
        card: "Card",
        online: "Online",
        cash: "Cash",
        payment: "Payment",
        isCardActive: "Is Card Payment Active",
        isCashActive: "Is Cash Payment Active",
        isOnlineActive: "Is Online Payment Active",
        exceptCategories: "Except Categories",
        statics: "Statics",
        orders: "Orders",
        detailedOrders: "Detailed Orders",
        priceWithoutKdv: "Price Without KDV",
        alcohol: "Alcohol",
        coldDrink: "Cold Drink",
        ibanName: "Iban Name",
        nameSurname: "Guest Name",
        buyPrice: "Buy Price",
        productHour: "Product Hour",
        hour: "Hour",
        updatedAt: "Updated At",
        firstName: "First Name",
        lastName: "Last Name",
        activities: "Activities",
        euro: "Euro",
        dollar: "Dollar",
        errors: {
            "is-require": "{{author}} zorunludur",
            "is-not-valid": "{{author}} geçerli değil",
            "please-enter-your": "lütfen {{author}} girin",
            "please-enter": "lütfen {{author}} girin",
            "please-select-your": "lütfen {{author}} girin",
            "must-be": "{{author}} {{kind}} olmalıdır",
            min: "The lowest character is {{author}}",
            max: "The maximum number of characters is {{author}}",
        },
        totalPriceTry: "Total Price Try",
        totalPriceEuro: "Total Price Euro",
        paymentCurrency: "Payment Currency",
        isRecommended: "Is Recommended",
        paymentPeriod: "Payment Period",
        limitAuthor: "Limit",
        // ------------------------------------ need turkey translation
        news: "News",
        product_text: "Product Text",
        catalog: "Catalog",
        about_us: "About Us",
        banners: "Banners",
        footer: "Footer",
        brands: "Brands",
        title: "Title",
        description: "Description",
        subtitle: "Subtitle",
        subImage: "Sub Image",
        subDescription: "Sub Description",
        descriptionLower: "Description Lower",
        subDescriptionLower: "Sub Description Lower",
        video: "Video",
        phones: "Phones",
        phone: "Phone",
        add_phone: "Add Phone",
        telephones: "Telephones",
        telephone: "Telephone",
        add_telephone: "Add Telephone",
        slug: "Link",
        list: "List",
        basic_information: "Basic Information",
        back_to: "Back To",
        add_translate: "Add Translate",
        remove: "Remove",
        translation: "Translation",
        file: "File",
        location: "Location",
        confirmPassword: "Confirm Password",
        password: "Password",
        showing: "Showing",
        out_of: "Out Of",
        entries: "Entries",
        no_data: "There is no data to show!",
        upload: "Upload",
        folder: "Folder",
        items: "Items",
        drag_drop: "Drag and drop your file !",
        accepted_file: "Accepted files: .jpg, .jpeg, .png, .pdf, .mp4",
        cancel: "Cancel",
        click_to_choose: "Click to choose from your device",
        or: "Or",
        size: "Size",
        type: "Type",
        upload_your_file: "Upload Your File",
        done: "Done",
        account: "Account",
        sign_out: "Sign Out",
        team_category: "Team Category",
        team: "Team",
        position: "Position",
        banner: "Banner",
        listOrderNumber: "List Order Number",
        KVKKTitle: "KVKK Title",
        KVKKDescription: "KVKK Description",
        missionsBanner: "Missions Banner",
        teamBanner: "Team Banner",
        map: "Map",
        contact_us: "Contact Us",
        message: "Message",
        subject: "Subject",
        isRead: "Is Read",
        missionTitle: "Mission Title",
        missionSubTitle: "Mission Subtitle",
        missionDescription: "Mission Description",
        visionTitle: "Vision Title",
        visionSubTitle: "Vision Subtitle",
        visionDescription: "Vision Description",
        show: "Show",
    },
};

export const en = baseStrings;
