const baseStrings = {
    translation: {
        name: "Ad",
        dashboard: "Dashboard",
        order: "Sipariş",
        product: "Ürün",
        category: "Kategori",
        user: "Kullanıcı",
        branch: "Bayi",
        hotel: "Otel",
        gallery: "Galleri",
        language: "Dil",
        setting: "Ayarlar",
        allStatus: "Tün Durumlar",
        delete: "Sil",
        add: "Ekle",
        edit: "Düzenle",
        from: "İle",
        to: "İle",
        submit: "Onayla",
        orderLists: "Sipariş Listesi",
        payment: "Ödeme",
        paymentType: "Ödeme Yöntemi",
        roomNumber: "Oda Numarası",
        products: "Ürünler",
        totalPrice: "Toplam Tutar",
        status: "Durum",
        note: "Not",
        createdAt: "Oluşturulma Tarihi",
        action: "İşlem",
        search: "Ara",
        newest: "En Yeni",
        image: "Resim",
        price: "Fiyat",
        translations: "Çeviriler",
        stock: "Stok",
        integrationCode: "Entegrasyon Kodu",
        isFastDelivery: "Hızlı Teslimat",
        isNewProduct: "Yeni Ürün",
        listOrderNumber: "Listeleme Sırası",
        addProduct: "Ürün Ekle",
        active: "Aktif",
        parent: "Ana",
        categoryType: "Kategory Türü",
        isNewCategory: "Yeni Kategori",
        email: "Email",
        emailNotification: "Email Bilgilendirme",
        phoneNotification: "SMS Bilgilendirme",
        role: "Yetki",
        commercialTitle: "Ticari Unvan",
        currentNotWorkingReason: "Kapanış Metni",
        startDate: "Açılış Saati",
        newsDate: "Tarih",
        endDate: "Kapanış Saati",
        address: "Adres",
        panelName: "Panel Adı",
        taxAdministration: "Vergi Dairesi",
        taxNo: "Vergi Numarası",
        code: "Kod",
        isSystemWorking: "Sistem Aktif",
        systemStartTime: "Sistem Açiliş Saati",
        systemEndTime: "Sistem Kapanış Saati",
        qrCode: "QR Code",
        users: "Kullanıcılar",
        menues: "Menuler",
        menue: "Menuler",
        disable: "Pasif",
        defaultLanguage: "Açılış Dili",
        drink: "İçecek",
        drinkInvoice: "İçecek Fatura",
        foodInvoice: "Yiyecek Fatura",
        invoice: "Fatura",
        formal: "Resmi",
        inFormal: "Gayri Resmi",
        orderLimit: "Sipariş Limiti",
        phoneNumber: "Telefon Numarası",
        roomCount: "Oda Sayısı",
        serviceCharge: "Service Charge",
        allOrders: "Toplam Siparişler",
        dailyOrders: "Günlük Siparişler",
        monthlyOrders: "Aylık Siparişler",
        total: "Toplam",
        finance: "Finans",
        totalAmount: "Toplam Tutar",
        commission: "Komisyon",
        all: "Hepsi",
        multiplePrice: "Toplu Fiyat",
        updateAuthor: "{{author}} Güncelle",
        addAuthor: "{{author}} Ekle",
        editAuthor: "{{author}} Düzenle",
        listAuthor: "{{author}} listesi",
        listsAuthor: "{{author}} Listesi",
        selectSearch: "Arama Yapılabilir",
        orderCount: "Sipariş Sayısı",
        totalCommission: "Toplam Komisyon",
        actions: "İşlemler",
        startTime: "Açılış Saati",
        endTime: "Kapanış Saati",
        isOrderLimit: "Sipariş Limiti",
        menus: "Menus",
        delivery: "Teslimat",
        deliveries: "Sürücü",
        places: "Yerler",
        orderNumber: "Sipariş Numarası",
        operation: "İşlem",
        operationSide: "İşlem Turu",
        operationUnit: "İşlem Değeri",
        exportExcel: "Download as an Excel",
        refund: "İade Alındı",
        canceled: "İptal Edildi",
        paymentSuccess: "Ödeme Başarılı",
        paymentFailed: "Ödeme Başarısız",
        waiting: "Bekliyor",
        sended: "Gönderildi",
        preparing: "Hazırlanıyor",
        delivered: "Teslim Edildi",
        onWay: "Yolda",
        totalOrders: "Toplam Sipariş",
        card: "Kart",
        online: "Online",
        cash: "Nakit",
        limitAuthor: "Adet {{author}}",
        oldest: "En Eski",
        isOnlineActive: "Online Ödeme",
        isCardActive: "Kapıda Kredi Kartı",
        isCashActive: "Nakit Ödeme",
        publish: "Kaydet",
        selectDate: "Tarih Seç",
        other: "Diğer",
        exceptCategories: "İstenmeyen Kategoriler",
        statics: "İstatistik",
        orders: "Sipariş",
        detailedOrders: "Detaylı Sipariş",
        priceWithoutKdv: "KDV Hariç Fiyat",
        alcohol: "Alkol",
        coldDrink: "Soğuk İçecek",
        ibanName: "Hesap Adı",
        nameSurname: "Misafir Adı",
        buyPrice: "Alış Fiyatı",
        productHour: "Ürün Saati",
        hour: "Saat",
        updatedAt: "Güncellenme Tarihi",
        firstName: "İsim",
        lastName: "Soyisim",
        activities: "Aktivite",
        errors: {
            "is-require": "{{author}} zorunludur",
            "is-not-valid": "{{author}} geçerli değil",
            "please-enter-your": "lütfen {{author}} girin",
            "please-enter": "lütfen {{author}} girin",
            "please-select-your": "lütfen {{author}} girin",
            "must-be": "{{author}} {{kind}} olmalıdır",
            min: "The lowest character is {{author}}",
            max: "The maximum number of characters is {{author}}",
        },
        totalPriceTry: "Toplan Tutar TL",
        totalPriceEuro: "Toplan Tutar Euro",
        paymentCurrency: "Ödeme Şekli",
        // ----------------------------------- new translations ------------------------
        // ------------------------------------ need turkey translation
        news: "haberler",
        product_text: "ürün metni",
        catalog: "katalog",
        about_us: "Hakkımızda",
        banners: "Afişler",
        footer: "Altbilgi",
        brands: "Markalar",
        title: "başlık",
        description: "Tanım",
        subtitle: "alt yazı",
        subImage: "alt resim",
        subDescription: "Alt Açıklama",
        descriptionLower: "Açıklama Alt",
        subDescriptionLower: "Alt Açıklama Alt",
        video: "Video",
        phones: "Telefonlar",
        phone: "Telefon",
        add_phone: "telefon ekle",
        telephones: "Telefonlar",
        telephone: "Telefon",
        add_telephone: "telefon ekle",
        slug: "Link",
        list: "liste",
        basic_information: "Temel Bilgiler",
        back_to: "geri dönmek",
        add_translate: "tercüme ekle",
        remove: "kaldırmak",
        translation: "tercüme",
        file: "dosya",
        location: "konum",
        confirmPassword: "Şifreyi Onayla",
        password: "şifre",
        showing: "gösteriliyor",
        out_of: "dışında",
        entries: "girdileri",
        no_data: "Gösterilecek veri yok!",
        upload: "yüklemek",
        folder: "dosya",
        items: "öğeler",
        drag_drop: "Dosyanızı sürükleyip bırakın!",
        accepted_file: "kabul edilen dosyalar: .jpg, .jpeg, .png, .pdf, .mp4",
        cancel: "iptal etmek",
        click_to_choose: "Cihazınızdan seçim yapmak için tıklayın",
        or: "veya",
        size: "Sboyut",
        type: "tip",
        upload_your_file: "Dosyanızı Yükleyin",
        done: "Tamamlandı",
        account: "hesap",
        sign_out: "oturumu Kapat",
        team_category: "Takım Kategorisi",
        team: "Takım",
        position: "Pozisyon",
        banner: "Afiş",
        listOrderNumber: "liste sıralaması",
        KVKKTitle: "KVKK başlığı",
        KVKKDescription: "KVKK Açıklaması",
        missionsBanner: "misyonlar afişi",
        teamBanner: "takım afişi",
        map: "harita",
        contact_us: "bize Ulaşın",
        message: "İleti",
        subject: "Konu",
        isRead: "Okundu",
        missionTitle: "görev başlığı",
        missionSubTitle: "görev altyazısı",
        missionDescription: "görev açıklaması",
        visionTitle: "vizyon başlığı",
        visionSubTitle: "vizyon altyazısı",
        visionDescription: "vizyon açıklaması",
        show: "göstermek",
    },
};

export const tr = baseStrings;
