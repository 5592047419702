import styles from "./GalleryComponent.module.scss";
import placeholder from "assets/images/placeholder.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";
import PdfIcon from "assets/images/pdf_icon.png";

const GalleryComponent = ({ description, selectedPhoto, handleModal, label, isRequired, Controller, name, control, errors, register, disables }) => {
  // const [state, setState] = useState(null);
  let newRegister = register ? register(name) : {};
  const [t] = useTranslation();
  console.log({ selectedPhoto });

  return (
    <div className={styles.galleryComponent}>
      <div className={styles.titleWrapper}>
        <label for="image">
          {compareTranslate(t, label || "image")}
          {isRequired && " *"}
          {description ? <small>{description}</small> : ""}
        </label>
        {handleModal ? (
          <button type={"button"} className={`${styles.button} btn btn-success btn-sm btn btn-primary`} onClick={handleModal}>
            <i className="mdi mdi-plus" />
          </button>
        ) : (
          ""
        )}
      </div>
      {errors && (
        <p className={"error-text mt-1"}>{!disables?.translate?.error ? compareTranslate(t, errors?.[name]?.message) : errors?.[name]?.message}</p>
      )}
      <Controller
        name={name}
        control={control}
        register={newRegister}
        render={({ field: { value } }) => {
          console.log({ value, selectedPhoto });
          return (
            <div className={styles.thumbnailWrapper}>
              {value?.mimetype?.startsWith("application/pdf") || selectedPhoto?.mimetype?.startsWith("application/pdf") ? (
                <img src={PdfIcon} alt="selected photo" style={{ width: "150px" }} />
              ) : value?.mimetype?.startsWith("image/") || selectedPhoto?.mimetype?.startsWith("image/") ? (
                <img src={value?.path || value?.src || selectedPhoto?.path || selectedPhoto?.src || value || placeholder} alt="thumbnail photo" />
              ) : value?.mimetype || selectedPhoto?.mimetype?.startsWith("video/") ? (
                <video
                  src={value?.path || value?.src || selectedPhoto?.path || selectedPhoto?.src || value || placeholder}
                  controls
                  style={{ maxWidth: "300px" }}
                />
              ) : !value && selectedPhoto?.endsWith(".pdf") ? (
                <img src={PdfIcon} alt="selected photo" style={{ width: "150px" }} />
              ) : !value && selectedPhoto?.endsWith(".mp4") ? (
                <video
                  src={value?.path || value?.src || selectedPhoto?.path || selectedPhoto?.src || value || placeholder}
                  controls
                  style={{ maxWidth: "300px" }}
                />
              ) : value && value?.endsWith(".pdf") ? (
                <img src={PdfIcon} alt="selected photo" style={{ width: "150px" }} />
              ) : value && value?.endsWith(".mp4") ? (
                <video
                  src={value?.path || value?.src || selectedPhoto?.path || selectedPhoto?.src || value || placeholder}
                  controls
                  style={{ maxWidth: "300px" }}
                />
              ) : (
                <img src={value?.path || value?.src || selectedPhoto?.path || selectedPhoto?.src || value || placeholder} alt="thumbnail photo" />
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default GalleryComponent;
