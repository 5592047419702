import login from "containers/Login/redux/saga";
import { all, fork } from "redux-saga/effects";
import accessToken from "./saga/accessToken";
import admin from "./saga/admin";
import refreshToken from "./saga/refreshToken";

function* watchers() {
  return yield all([fork(login), fork(accessToken), fork(refreshToken), fork(admin)]);
}

export default watchers;
