import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  email: "email",
  location: "location",
  phones: "phones",
  telephones: "telephones",
};

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.email)).label(fieldNames.email),
    [fieldNames.location]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.location)).label(fieldNames.location),
    [fieldNames.phones]: yup
      .array()
      .of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.phones)))
      .defined(),
    [fieldNames.telephones]: yup
      .array()
      .of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.telephones)))
      .defined(),
  });
