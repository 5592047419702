import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldText from "components/FieldText";
import GalleryComponent from "components/gallery/GalleryComponent";
import GalleryModal from "components/gallery/GalleryModal";
import LanguageSelector from "containers/Language/LanguageSelector";
import { STATUSES } from "enumerations";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";

const Add = () => {
  const { t } = useTranslation();

  // ------------------------------------ route
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

  // ----------------------------------------------------------------------------- Fetching Functions
  const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
  const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
  const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);

  const navigation = useNavigate();
  let { id } = useParams();
  const URL_ID = id ? id : "";
  // ----------------------------------------------------------------------------- Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
  });

  // ----------------------------------------------------------------------------- Mutation
  const onSuccessMutating = () => {
    toastify.success({ title: "success" });
    navigation(newRoute.base);
  };
  const { isLoading, data, mutate } = useMutationCustom({
    url: URL_ID ? updating : creating,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: onSuccessMutating,
  });

  // ----------------------------------------------------------------------------- Query By ID
  const onSuccessDataById = ({ data }) => {
    let params = { ...data };
    reset({
      ...params,
      phones: params.phones.map((x) => x),
      telephones: params.telephones.map((x) => x),
    });
  };
  const { data: dataById } = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: getById,
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });
  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const { ...values } = getValues();
    console.log({ values });
    const requestData = {
      ...values,
    };
    console.log({ requestData });
    mutate(requestData);
  };

  // ----------------------------------------------------------------------------- Constance
  const dispatch = useDispatch();
  const button = {
    title: `${t("back_to")} ${t(COMPONENT_NAMES[0])} ${t("list")}`,
    link: newRoute.base + newRoute.list,
  };

  const breadcrumbs = [
    // { title: t("dashboard"), link: routes.dashboard },
    { title: COMPONENT_NAMES[0], link: newRoute.base },
    {
      title: URL_ID ? `${t("edit")}/ ${dataById?.data?.id}` : "add",
    },
  ];

  const defaultData = [];
  const defaultDataTelephones = [];

  const addNewPhones = () => {
    const values = getValues();
    const newData = values[fieldNames.phones] ? [...values[fieldNames.phones], [...defaultData]] : [[...defaultData]];
    console.log({ newData });
    setValue(fieldNames.phones, newData);
  };

  const addNewTelephones = () => {
    const values = getValues();
    const newData = values[fieldNames.telephones] ? [...values[fieldNames.telephones], [...defaultData]] : [[...defaultData]];
    console.log({ newData });
    setValue(fieldNames.telephones, newData);
  };
  // ---------------------------------------------- EFFECTS
  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, [dataById]);
  // ----------------------------------------------------------------------------- Log
  const values = getValues();
  console.log({ dataById, values }, "Add");
  console.log({ URL_ID });
  console.log({ errors }, "errors");

  return (
    <div>
      <PageTitle title={URL_ID ? `${t("edit")}  ${t(COMPONENT_NAMES[0])}` : `${t("add")}  ${t(COMPONENT_NAMES[0])}`} button={button} />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("basic_information")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        {...{
                          name: fieldNames.email,
                          register,
                          placeholder: "email",
                          label: "email",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.location,
                          register,
                          placeholder: "location",
                          label: "location",
                          errors,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 border p-4 mt-4">
              <div className="">
                <Row className="b-1">
                  <Col lg={6}>
                    <h2 className="">{t("phones")}</h2>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    <Button onClick={addNewPhones} className={"d-flex align-items-center"} size="sm">
                      <div className={"gallery-add-title"}>{t("add_phone")}</div>
                      <div className={"ml-1"}>+</div>
                    </Button>
                  </Col>
                </Row>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form"></div>
                    <Controller
                      name={fieldNames.phones}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        console.log({ value });

                        return Children.toArray(
                          value?.map?.((locale, index) => {
                            const values2 = getValues();
                            const removeClick = (index) => {
                              let values = getValues();
                              if (!values[fieldNames.phones]) return;
                              const newValues = values[fieldNames.phones].filter((param, i) => i !== index);
                              setValue(fieldNames.phones, newValues);
                            };

                            return (
                              <Row className="translation-row" key={value?.name || index}>
                                {index >= 0 ? (
                                  <>
                                    <Col lg={10}>
                                      <h5>{`${t("phone")} ${index + 1}`}</h5>
                                    </Col>
                                    <Col lg={2}>
                                      <div>
                                        <Button
                                          color="warning"
                                          className={"d-flex align-items-center"}
                                          size="sm"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            removeClick(index);
                                          }}
                                        >
                                          {t("remove")}
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col lg={12}>
                                      <hr style={{ marginBottom: 0 }} />
                                    </Col>
                                  </>
                                ) : (
                                  ""
                                )}

                                <FieldText
                                  require
                                  {...{
                                    name: `phones[${index}]`,
                                    placeholder: "phone",
                                    label: "phone",
                                    error: errors.phones?.[index],

                                    control,
                                  }}
                                />
                              </Row>
                            );
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 border p-4 mt-4">
              <div className="">
                <Row className="b-1">
                  <Col lg={6}>
                    <h2 className="">{t("telephones")}</h2>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    <Button onClick={addNewTelephones} className={"d-flex align-items-center"} size="sm">
                      <div className={"gallery-add-title"}>{t("add_telephone")}</div>
                      <div className={"ml-1"}>+</div>
                    </Button>
                  </Col>
                </Row>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form"></div>
                    <Controller
                      name={fieldNames.telephones}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        console.log({ value });

                        return Children.toArray(
                          value?.map?.((locale, index) => {
                            const values2 = getValues();
                            const removeClick = (index) => {
                              let values = getValues();
                              if (!values[fieldNames.telephones]) return;
                              const newValues = values[fieldNames.telephones].filter((param, i) => i !== index);
                              setValue(fieldNames.telephones, newValues);
                            };

                            return (
                              <Row className="translation-row" key={value?.name || index}>
                                {index >= 0 ? (
                                  <>
                                    <Col lg={10}>
                                      <h5>{`${t("telephone")} ${index + 1}`}</h5>
                                    </Col>
                                    <Col lg={2}>
                                      <div>
                                        <Button
                                          color="warning"
                                          className={"d-flex align-items-center"}
                                          size="sm"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            removeClick(index);
                                          }}
                                        >
                                          {t("remove")}
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col lg={12}>
                                      <hr style={{ marginBottom: 0 }} />
                                    </Col>
                                  </>
                                ) : (
                                  ""
                                )}

                                <FieldText
                                  require
                                  {...{
                                    name: `telephones[${index}]`,
                                    placeholder: "telephone",
                                    label: "telephone",
                                    error: errors.telephones?.[index],

                                    control,
                                  }}
                                />
                              </Row>
                            );
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md="4">
            <div className="row">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{t("actions")}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};

export default Add;
