import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
    name: "name",
    listOrderNumber: "listOrderNumber",
    translations: "translations",
    translate_name: "translate_name",
    translate_language: "translate_language",
};

const translations = yup
    .object({
        [fieldNames.translate_name]: yup.string().required(ERRORS.pleaseEnterYour("name")).label("name"),
        [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
    })
    .defined();

export const validation = (URL_ID) =>
    yup.object().shape({
        [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
        [fieldNames.listOrderNumber]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.listOrderNumber)).label(fieldNames.listOrderNumber),
        [fieldNames.translations]: yup.array().of(translations),
    });
