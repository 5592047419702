import toastify from "lib/toastify";
import { useMutation, useQueryClient } from "react-query";
import { changeError } from "utils/errors";

export function useMutationCustom({ name, url, onSuccess, invalidQuery }) {
  const queryClient = useQueryClient();


  return useMutation(name ? name : "", url, {
    onSuccess,
    onError: (error) => {
      const changedError = changeError(error);
      // console.log({ error, changedError });
      Object.entries(changedError).map(([key, value]) => {
        // console.log({ value, key }, "error");

        return toastify.error({ title: value.message || value });
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(invalidQuery);
    },
  });
}
