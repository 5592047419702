import login from "containers/Login/redux/store/reducer";
import { combineReducers } from "redux";
import accessToken from "./store/accessToken/reducer";
import admin from "./store/admin/reducer";
import breadcrumbs from "./store/breadcrumbs/reducer";
import refreshToken from "./store/refreshToken/reducer";
import token from "./store/token/reducer";

const rootReducer = () =>
  combineReducers({
    admin: admin,
    accessToken: accessToken,
    refreshToken: refreshToken,
    login: login,
    breadcrumbs: breadcrumbs,
    token,
  });

export default rootReducer;
