import React from 'react'
import { Children } from 'react';
import "./index.scss";

function TableSkeleton(params) {
    return (
        <table className="tg">
            <tr>
                <th className="tg-cly1">
                    <div className="line"></div>
                </th>
                <th className="tg-cly1">
                    <div className="line"></div>
                </th>
                <th className="tg-cly1">
                    <div className="line"></div>
                </th>
                <th className="tg-cly1">
                    <div className="line"></div>
                </th>
                <th className="tg-cly1">
                    <div className="line"></div>
                </th>
                <th className="tg-cly1">
                    <div className="line"></div>
                </th>
                <th className="tg-cly1">
                    <div className="line"></div>
                </th>
                <th className="tg-cly1">
                    <div className="line"></div>
                </th>
            </tr>
            {Children.toArray(Array.apply(null, new Array(10)).map((a, i) => (
                <tr>
                    <td className="tg-cly1">
                        <div className="line"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line"></div>
                    </td>
                </tr>
            )))}


        </table>
    )
}

export default TableSkeleton;