import React, { Children, PropTypes } from "react";
import { Dropdown } from "react-bootstrap";

const DropDownBootstrap = ({ data, title, subtitle, value, click, ...p }) => {
  return (
    <Dropdown {...p}>
      <Dropdown.Toggle className="limit-dropDown mr-3 btn-sm">{title}</Dropdown.Toggle>
      <Dropdown.Menu className="dropDown-menu-custom">
        {subtitle ? <Dropdown.Header header>{subtitle}</Dropdown.Header> : ""}
        {Children.toArray(
          data.map((data) => {
            return (
              <Dropdown.Item key={data.value} className="dropDown-item-custom mt-2" active={value === data.value} onClick={() => click(data)}>
                {data.label}
              </Dropdown.Item>
            );
          })
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default DropDownBootstrap;
