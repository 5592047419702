import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
  email: "email",
  phone: "phone",
  subject: "subject",
  text: "text",
  isRead: false,
};

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.name]: yup.string().label(fieldNames.name),
    [fieldNames.email]: yup.string().label(fieldNames.email),
    [fieldNames.phone]: yup.string().label(fieldNames.phone),
    [fieldNames.subject]: yup.string().label(fieldNames.phone),
    [fieldNames.text]: yup.string().label(fieldNames.text),
    [fieldNames.isRead]: yup.boolean().label(fieldNames.isRead),
  });
