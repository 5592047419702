import React, { useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actionUser from "redux/store/admin/action";
import { useNavigate } from "react-router-dom";
import routes from "operations/routing/routes";
import { fieldNames, validation } from "./validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import action from "./redux/store/action";
import logo from "assets/images/logo.svg";
import FieldText from "components/FieldText";
import Spinner from "components/Spinner";
import Loading from "components/Loading";
import AlertComponent from "components/AlertComponent";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.login);
  const adminState = useSelector((state) => state.admin);
  const tokenState = useSelector((state) => state.token);
  // const verifyState = useSelector((state) => state.verify);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(validation),
    mode: "onSubmit",
  });

  // useEffect(() => {
  //   const res = axiosClient.post(api.login, {
  //     "email": "matin@gmail.com",
  //     "password": "matin2022@M!20"
  //   }).then((res) => {
  //     cLog({ res });
  //   }).catch((err) => {  dispatch.request(values); })
  // }, [])

  const onSubmit = () => {
    const values = getValues();
    console.log({ values });
    dispatch(action.request(values));
  };

  useEffect(() => {
    if (state?.data == "verify") {
      navigate(routes.verify);
    } else if (state?.data) {
      navigate(routes.product.base);
    }
  }, [state]);

  useEffect(() => {
    if (adminState?.data || tokenState?.data) {
      dispatch(actionUser.logout());
      cookies.remove("accessToken");
    }
  }, []);

  console.log({ errors, state });

  return (
    <div className="d-flex align-items-center auth px-0">
      <div className="row w-100 mx-0">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="brand-logo">
              {/* <img src={logo} alt="logo" /> */}
              <div className="logo-text text-center">
                <h2>Welcome to</h2>
                <h2>
                  <span className="text-primary">kırtıloğlu</span> dashboard
                </h2>
              </div>
            </div>
            <h6 className="font-weight-light text-center">Sign in to continue</h6>
            <Form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
              {/* <Form.Group className="mb-3 d-flex search-field"> */}
              <AlertComponent error={state.errorMessage} />
              <FieldText
                require
                className="mb-3 d-flex search-field"
                {...{
                  name: fieldNames.email,
                  register,
                  type: "email",
                  placeholder: "Email",
                  size: "lg",
                  className: "h-auto",
                  errors,
                }}
              />
              {/* <Form.Control {...register(fieldNames.email)} type="email" placeholder="Username" size="lg" className="h-auto" /> */}
              {/* </Form.Group> */}
              {/* <Form.Group className="mb-3 d-flex search-field">
                <Form.Control {...register(fieldNames.password)} type="password" placeholder="Password" size="lg" className="h-auto" />
              </Form.Group> */}
              <FieldText
                require
                className="mb-3 d-flex search-field"
                {...{
                  name: fieldNames.password,
                  register,
                  type: "password",
                  placeholder: "Password",
                  size: "lg",
                  className: "h-auto",
                  errors,
                }}
              />

              {/* <div className="form-check">
                <label className="form-check-label text-muted">
                  <input type="checkbox" className="form-check-input" {...register(fieldNames.rememberMe)} />
                  <i className="input-helper"></i>
                  Remember me
                </label>
              </div> */}
              <div className="mt-3">
                <button disabled={state?.isFetching} className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                  {state?.isFetching ? <Loading /> : "SIGN IN"}
                </button>
              </div>
              <div className="my-2 d-flex justify-content-between align-items-center">
                <div className="form-check">
                  {/* <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input"/>
                        <i className="input-helper"></i>
                        Keep me signed in
                      </label> */}
                </div>
                {/* <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-black">Forgot password?</a> */}
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div class="footerLogin">
        <div class="copyright">
          <p>
            Copyright © 2022{" "}
            <a href="#" target="_blank">
              {" "}
              kırtıloğlu
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
