import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
    name: "name",
    image: "image",
    position: "position",
    listOrderNumber: "listOrderNumber",
    translations: "translations",
    translate_language: "translate_language",
    translate_position: "translate_position",
    translate_name: "translate_name",
    translate_language: "translate_language",
    category: "category",
};

const translations = yup
    .object({
        [fieldNames.translate_name]: yup.string().required(ERRORS.pleaseEnterYour("name")).label("name"),
        [fieldNames.translate_position]: yup.string().required(ERRORS.pleaseEnterYour("position")).label("position"),
        [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
    })
    .defined();

export const validation = (URL_ID) =>
    yup.object().shape({
        [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
        [fieldNames.position]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.position)).label(fieldNames.position),
        [fieldNames.listOrderNumber]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.listOrderNumber)).label(fieldNames.listOrderNumber),
        [fieldNames.category]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.category)).label(fieldNames.category),
        [fieldNames.image]: yup.lazy((value) => {
            switch (typeof value) {
                case "object":
                    return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for object
                case "string":
                    return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for string
                default:
                    return yup.mixed(); // here you can decide what is the default
            }
        }),
        [fieldNames.translations]: yup.array().of(translations),
    });
