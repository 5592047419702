import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  KVKKTitle: "KVKKTitle",
  KVKKDescription: "KVKKDescription",
  b2b: "b2b",
  facebook: "facebook",
  instagram: "instagram",
  whatsapp: "whatsapp",
  linkedin: "linkedin",
  teamBanner: "teamBanner",
  map: "map",
  translations: "translations",
  translate_language: "translate_language",
  translate_KVKKTitle: "translate_KVKKTitle",
  translate_KVKKDescription: "translate_KVKKDescription",
};

const translations = yup
  .object({
    [fieldNames.translate_KVKKTitle]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.KVKKTitle)).label(fieldNames.KVKKTitle),
    [fieldNames.translate_KVKKDescription]: yup
      .string()
      .required(ERRORS.pleaseEnterYour(fieldNames.KVKKDescription))
      .label(fieldNames.KVKKDescription),
    [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
  })
  .defined();

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.KVKKTitle]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.KVKKTitle)).label(fieldNames.KVKKTitle),
    [fieldNames.KVKKDescription]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.KVKKDescription)).label(fieldNames.KVKKDescription),
    [fieldNames.b2b]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.b2b)).label(fieldNames.b2b),
    [fieldNames.facebook]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.facebook)).label(fieldNames.facebook),
    [fieldNames.instagram]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.instagram)).label(fieldNames.instagram),
    [fieldNames.whatsapp]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.whatsapp)).label(fieldNames.whatsapp),
    [fieldNames.linkedin]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.linkedin)).label(fieldNames.linkedin),

    [fieldNames.teamBanner]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.teamBanner)).label(fieldNames.teamBanner); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.teamBanner)).label(fieldNames.teamBanner); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),
    [fieldNames.map]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.map)).label(fieldNames.map); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.map)).label(fieldNames.map); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),
    [fieldNames.translations]: yup.array().of(translations),
  });
