import initialState from "./initialState";
import Cookies from "universal-cookie/es6";

export const requestFunc = (state, { queryOptions }) => ({
  ...state,
  isFetching: true,
  queryOptions,
});

export const receiveFunc = (state, { data }) => ({
  ...state,
  isFetching: false,
  errorMessage: null,
  data,
});

export const failureFunc = (state, { errorMessage }) => ({
  ...state,
  isFetching: false,
  errorMessage,
});

export const initialFunc = (ctx) => {
  console.log("access toke initial");
  const cookies = new Cookies();

  // destroyCookie(null, 'accessToken');
  cookies.remove("accessToken", { path: "/" });
  return { ...initialState };
};
