import React, { Component, useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { AccordionContext, Dropdown } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import routes from "operations/routing/routes";
import { PERMISSIONS } from "enumerations";
import { useSelector } from "react-redux";
import { findElementsInTwoArrays } from "utils/findElementsInTwoArrays";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const [state, setState] = useState({});

  let location = useLocation();

  function toggleMenuState(menuState) {
    if (state[menuState]) {
      setState((p) => ({ ...p, [menuState]: false }));
    } else if (Object.keys(state).length === 0) {
      setState((p) => ({ ...p, [menuState]: true }));
    } else {
      Object.keys(state).forEach((i) => {
        setState((p) => ({ ...p, [i]: false }));
      });
      setState((p) => ({ ...p, [menuState]: true }));
    }
  }

  function onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(state).forEach((i) => {
      setState((p) => ({ ...p, [i]: false }));
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setState((p) => ({ ...p, [obj.state]: true }));
      }
    });
  }

  function isPathActive(path) {
    return location?.pathname?.startsWith(path);
  }

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  useEffect(() => {
    onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, [location]);

  function ContextAwareToggle({ children, eventKey, callback, icon, dropdown }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <div className={isCurrentEventKey ? "nav-link menu-expanded" : "nav-link"} onClick={decoratedOnClick} data-toggle="Accordion.collapse">
        {icon}
        <span className="menu-title">{children}</span>
        {dropdown ? <i className="menu-arrow"></i> : ""}
      </div>
    );
  }

  const adminState = useSelector((state) => state.admin);
  const isBranch = true;
  const isHotel = true;
  const isAdmin = true;

  const sidebar = [
    // {
    //   icon: <i className="mdi mdi-home menu-icon"></i>,
    //   title: "dashboard",
    //   link: routes.dashboard,
    //   permission: true,
    //   name: "dashboard",
    // },
    {
      icon: <i className="mdi mdi-account menu-icon"></i>,
      title: "user",
      link: routes.user.base,
      permission: true,
      name: "users",
    },
    {
      icon: <i className="mdi mdi-film menu-icon"></i>,
      title: "banners",
      link: routes.banners.base,
      permission: true,
      name: "banners",
    },
    {
      icon: <i className="mdi mdi-kettle menu-icon"></i>,
      title: "brands",
      link: routes.brands.base,
      permission: true,
      name: "brands",
    },
    {
      icon: <i className="mdi mdi-face menu-icon"></i>,
      title: "about_us",
      link: routes.about_us.base,
      permission: true,
      name: "aboutUs",
    },
    {
      icon: <i className="mdi mdi-barcode-scan menu-icon"></i>,
      title: "product_text",
      link: routes.productText.base,
      permission: true,
      name: "productText",
    },
    {
      icon: <i className="mdi mdi-basket menu-icon"></i>,
      title: "product",
      link: routes.product.base,
      permission: true,
      name: "product",
    },

    {
      icon: <i className="mdi mdi-file-image menu-icon"></i>,
      title: "catalog",
      link: routes.catalog.base,
      permission: true,
      name: "catalog",
    },

    {
      icon: <i className="mdi mdi-email-open menu-icon"></i>,
      title: "news",
      link: routes.news.base,
      permission: true,
      name: "news",
    },
    {
      icon: <i className="mdi mdi-gnome menu-icon"></i>,
      title: "Footer",
      link: routes.footer.base,
      permission: true,
      name: "footer",
    },

    {
      icon: <i className="mdi mdi-account-multiple menu-icon"></i>,
      title: "team",
      link: routes.team.base,
      permission: true,
      name: "team",
    },
    {
      icon: <i className="mdi mdi-nature-people menu-icon"></i>,
      title: "team_category",
      link: routes.team_category.base,
      permission: true,
      name: "team_category",
    },

    {
      icon: <i className="mdi mdi-google-translate menu-icon"></i>,
      title: "language",
      link: routes.language.base,
      permission: true,
      name: "languages",
    },
    {
      icon: <i className="mdi mdi-airballoon menu-icon"></i>,
      title: "missions",
      link: routes.missions.base,
      permission: true,
      name: "missions",
    },
    {
      icon: <i className="mdi mdi-image-multiple menu-icon"></i>,
      title: "gallery",
      link: routes.gallery.base,
      permission: true,
      name: "files",
    },
    {
      icon: <i className="mdi mdi-settings menu-icon"></i>,
      title: "setting",
      link: routes.setting.base,
      permission: true,
      name: "setting",
    },
    {
      icon: <i className="mdi mdi-contact-mail menu-icon"></i>,
      title: "contact_us",
      link: routes.contactUs.base,
      permission: true,
      name: "contact_us",
    },
  ];
  const [t] = useTranslation();
  return (
    <nav className="sidebar sidebar-offcanvas sidebar-fixed" id="sidebar">
      <Accordion defaultActiveKey="0">
        <ul className="nav">
          {sidebar.map((x, i) => {
            const permission = true;
            // const disabled = adminState.data.operation.disabled[x?.name];
            const disabled = false;
            const parentPermission = permission === true ? true : permission?.[x?.name];
            return (x.permission ? parentPermission : true) && !disabled ? (
              x.link ? (
                <li key={x.title}>
                  <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}>
                    <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data}>
                      {t(x.title)}
                    </ContextAwareToggle>
                    {x?.data ? (
                      <Accordion.Collapse eventKey={i}>
                        <ul className="nav flex-column sub-menu">
                          {x.data.map((y) => {
                            const childPermission = permission === true ? true : permission?.[x?.name];
                            console.log({ childPermission });
                            return !y.permission || childPermission ? (
                              <li key={y.title} className="nav-item">
                                <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                  {y.title}
                                </NavLink>
                              </li>
                            ) : (
                              ""
                            );
                          })}
                        </ul>
                      </Accordion.Collapse>
                    ) : (
                      ""
                    )}
                  </NavLink>
                </li>
              ) : (
                <li key={x.title} className="nav-item nav-link">
                  {/* <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}> */}
                  <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data}>
                    {t(x.title)}
                  </ContextAwareToggle>
                  {x?.data ? (
                    <Accordion.Collapse eventKey={i}>
                      <ul className="nav flex-column sub-menu">
                        {x.data.map((y) => {
                          const childPermission = permission === true ? true : permission?.[y?.name];

                          return !y.permission || childPermission ? (
                            <li key={y.title} className="nav-item">
                              <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                {y.title}
                              </NavLink>
                            </li>
                          ) : (
                            ""
                          );
                        })}
                      </ul>
                    </Accordion.Collapse>
                  ) : (
                    ""
                  )}
                  {/* </NavLink> */}
                </li>
              )
            ) : (
              ""
            );
          })}
        </ul>
      </Accordion>
    </nav>
  );
};

export default Sidebar;
