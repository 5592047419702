import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "image",
    Component: ({ param }) => {
      return (
        <>
          <span className="d-block mb-1">
            <img src={param?.image} className="image-fluid" />
          </span>
        </>
      );
    },
  },
  {
    header: "description",
    Component: ({ param }) => (
      <span id="ellipsis-ex" class="d-inline-block text-truncate" style={{ maxWidth: "150px" }}>
        {param.description || "Unknown"}
      </span>
    ),
  },
  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
];
